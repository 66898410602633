import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { SiSuperuser } from 'react-icons/si';
import { HiTemplate } from 'react-icons/hi';
import { FaImages, FaUser, FaUsers } from 'react-icons/fa';
import { IoSettings } from 'react-icons/io5';
import { VscFile } from 'react-icons/vsc';

import { withTranslation } from 'react-i18next';

import Context from '../../pages/Context';

import classes from './Sidebar.module.css';
class Sidebar extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			currentRoute: '/',

			items: [],
			transPage: 'sidebar.',
		};
	}

	componentDidMount() {
		const { t } = this.props;
		const { transPage } = this.state;

		this.setState(
			{
				items: [
					{
						key: 1,
						name: t(transPage + 'projects'),
						url: '/projects',
						html: (
							<svg xmlns='http://www.w3.org/2000/svg' width='32' height='28' viewBox='0 0 32 28'>
								<g transform='translate(2157 -163)'>
									<rect className={classes.a} width='30' height='21' rx='2' transform='translate(-2156 164)' />
									<rect className={classes.b} width='22' height='5' transform='translate(-2152 168)' />
									<rect className={classes.b} width='10' height='5' transform='translate(-2146 185)' />
									<rect className={classes.b} width='22' height='2' transform='translate(-2152 189)' />
									<rect className={classes.b} width='15' height='6' transform='translate(-2145 175)' />
									<rect className={classes.b} width='5' height='6' transform='translate(-2152 175)' />
								</g>
							</svg>
						),
						superuser: false,
					},
					{
						key: 2,
						name: t(transPage + 'organization_managers'),
						url: '/organization-managers',
						html: <FaUsers width='27' height='27' color='#5F5F5F' className={classes.b} />,
						superuser: false,
					},
					{
						key: 3,
						name: t(transPage + 'managers'),
						url: '/managers',
						html: <FaUsers width='27' height='27' color='#5F5F5F' className={classes.b} />,
						superuser: false,
					},
					{
						key: 4,
						name: t(transPage + 'statistics'),
						url: '/statistics',
						html: (
							<svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24'>
								<g transform='translate(2187 -308.5)'>
									<line className={classes.c} y2='11' transform='translate(-2183.5 321.5)' />
									<line className={classes.c} y2='24' transform='translate(-2174.5 308.5)' />
									<line className={classes.c} y2='16' transform='translate(-2165.5 316.5)' />
								</g>
							</svg>
						),
						superuser: false,
					},
					{
						key: 5,
						name: t(transPage + 'assets'),
						url: '/assets',
						html: <FaImages width='27' height='27' color='#5F5F5F' className={classes.d} />,
						superuser: false,
					},
					{
						key: 6,
						name: t(transPage + 'template_configurator'),
						url: '/template-configurator',
						html: (
							<svg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27'>
								<g transform='translate(2188 -393)'>
									<rect className={classes.b} width='12' height='15' transform='translate(-2188 393)' />
									<rect className={classes.b} width='12' height='15' transform='translate(-2173 405)' />
									<rect className={classes.b} width='12' height='10' transform='translate(-2173 393)' />
									<rect className={classes.b} width='12' height='10' transform='translate(-2188 410)' />
								</g>
							</svg>
						),
						superuser: false,
					},
					{
						key: 7,
						name: t(transPage + 'organizations'),
						url: '/organizations',
						html: <IoSettings width='27' height='27' color='#5F5F5F' className={classes.d} />,
						superuser: true,
					},
					{
						key: 8,
						name: t(transPage + 'owners'),
						url: '/owners',
						html: <FaUser width='27' height='27' color='#5F5F5F' className={classes.d} />,
						superuser: true,
					},
					{
						key: 9,
						name: t(transPage + 'control_panel'),
						url: '/control-panel',
						html: <SiSuperuser width='27' height='27' color='#5F5F5F' className={classes.d} />,
						superuser: true,
					},
					{
						key: 10,
						name: t(transPage + 'email_template'),
						url: '/email-template',
						html: <HiTemplate width='27' height='27' color='#5F5F5F' className={classes.d} />,
						superuser: true,
					},
					{
						key: 11,
						name: t(transPage + 'reports'),
						url: '/reports',
						html: <VscFile width='27' height='27' color='#5F5F5F' className={classes.d} />,
						superuser: true,
					},
					{
						key: 12,
						name: t(transPage + 'reports'),
						url: '/reports',
						html: <VscFile width='27' height='27' color='#5F5F5F' className={classes.d} />,
						superuser: true,
					},
				],
			},
			() => {
				this.setState({
					currentRoute: this.props.location.pathname,
				});
			}
		);
	}

	render() {
		const { currentRoute, items } = this.state;
		const { user, Organization } = this.context;

		let item;
		if (user) {
			const isOrganization = Organization?.length > 0
			let cl = JSON.parse(user);
			const groups = cl.groups ? cl.groups[0] : false;
			item = items.map((itm, i) => {
				let active = currentRoute === itm.url ? classes.active : '';
				let separator = i === 3 ? classes.separator : '';
				let totalSeparator = (currentRoute.match(/\//g) || []).length;
				let html;
				let content = (
					<Link key={`${itm.url}-${i}`} to={itm.url} className={[classes.link, active, separator].join(' ')} style={{ pointerEvents: isOrganization ? 'all' : 'none' }}>
						{itm.html}
						{itm.name}
						{i === 3 && <span>BETA</span>}
					</Link>
				);

				if ([0, 3].includes(i)) {
					html = content;
				} else if (i === 1) {
					if (cl.is_superuser) {
						html = content;
					} else {
						if (groups && (groups.id === 7 || groups.name === 'Owner')) {
							html = content;
						}
					}
				} else if (i === 2) {
					if (cl.is_superuser) {
						html = content;
					} else {
						if (groups && ([7, 9].includes(groups.id) || ['Owner', 'Organization Manager'].includes(groups.name))) {
							html = content;
						} else if (groups && (groups.id === 2 || groups.name === 'Manager')) {
							if (totalSeparator && totalSeparator > 1) {
								const a = currentRoute.split('/').slice(0, 2);
								const b = a.join('/');
								active = itm.url.includes(b) ? classes.active : '';
							}
							html = (
								<Link key={`${itm.html}-${i}`} to={`/manager/edit/${cl.id}`} className={[classes.link, active, separator].join(' ')}>
									{itm.html}
									{itm.name}
								</Link>
							);
						}
					}
				} else if (i === 4) {
					if (cl.is_superuser) {
						html = content;
					}
				} else if (i === 5) {
					if (cl.is_superuser || (cl.is_superuser === itm.superuser && groups && (groups.id === 7 || groups.name === 'Owner'))) {
						html = content;
					}
				} else if (i === 6) {
					if (cl.is_superuser === itm.superuser) {
						html = content;
					} else {
						if (groups && (groups.id === 7 || groups.name === 'Owner')) {
							html = content;
						}
					}
				} else if (i === 7) {
					if (cl.is_superuser === itm.superuser) {
						html = content;
					}
				} else if (i === 8) {
					if (cl.is_superuser === itm.superuser) {
						html = content;
					}
				} else if (i === 9) {
					if (cl.is_superuser === itm.superuser) {
						html = content;
					}
				} else if (i === 10) {
					if (cl.is_superuser === itm.superuser) {
						html = content;
					}
				}
				return html;
			});
		}

		return (
			<aside className={classes.sidebar}>
				<div className={classes.contentSidebar}>{item}</div>
			</aside>
		);
	}
}

// export default withTranslation()(ResetPassword);
// export default withRouter((props) => <Sidebar {...props} />);
export default withTranslation()(withRouter((props) => <Sidebar {...props} />));
