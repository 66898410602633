import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withTranslation } from 'react-i18next';

import Context from "../../../pages/Context";
import Loader from "../../Loader/Loader";
import ClientApi from "../../../api/ClientApi";
import OrganizationApi from "../../../api/OrganizationApi";

import classes from "./ActivateProject.module.css";

class ActivateProject extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			Clients: [],
			Organization: [],
			isData: true,

			totalMonth: 1,
			isPrivacy: false,
			canActivate: false,

			transPage: 'activate_project_form.'
		};
	}

	onOpenModal = () => {
		// this.getClientDetail();
		// this.getOrganizationDetail();
	};

	getClientDetail = () => {
		const { user } = this.context;
		const CA = new ClientApi();

		if (user) {
			const us = JSON.parse(user);
			CA.getClientDetail(us.client)
				.then((res) => {
					this.setState({ Clients: res.data, isData: true }, () => { });
				})
				.catch((err) => console.log(err));
		}
	};

	getOrganizationDetail = () => {
		const { user, activeOrganization } = this.context;
		const OA = new OrganizationApi();

		if (user && activeOrganization) {
			OA.getOrganizationDetail(activeOrganization)
				.then((res) => {
					this.setState({ Organization: res.data }, () => { });
				})
				.catch((err) => console.log(err));
		}
	};

	action = () => {
		const { isData } = this.state;
		const project = this.props.project;

		const dateFrom = document.getElementById("date-from").value

		if (isData && dateFrom) {
			const data = {
				project: project?.id,
				reference: document.getElementById("inv-reference").value,
				date_from: document.getElementById("date-from").value,
				number_of_month: document.getElementById("number-month").value,
			};
			this.props.action(data);
		}
	};

	totalMonthChanged = (e) => {
		const val = e.target.value
		if (val) {
			this.setState({
				totalMonth: +val
			}, () => {
				this.checkingValue();
			})
		}
	}

	privacyOnChange = (e) => {
		this.setState(
			{
				isPrivacy: e.target.checked,
			},
			() => {
				this.checkingValue();
			}
		);
	};

	checkingValue = () => {
		const dateFrom = document.getElementById("date-from").value
		const number_of_month = document.getElementById("number-month").value;

		if (dateFrom && number_of_month > 0 && this.state.isPrivacy) {
			this.setState({
				canActivate: true,
			});
		} else {
			this.setState({
				canActivate: false,
			});
		}
	};

	render() {
		const { user } = this.context;
		const { isData } = this.state;
		const project = this.props.project;
		const { t } = this.props;
		const { transPage, canActivate } = this.state

		const priceTemplate = project?.template?.rate

		const us = JSON.parse(user);
		return (
			<>
				<Modal show={this.props.show} onHide={this.props.onHide} size="lg" onShow={this.onOpenModal} className="modal-bim">
					{isData ? (
						<>
							<Modal.Header closeButton className="modal-bim-header">
								<Modal.Title>{t(transPage + 'activate_project')}</Modal.Title>
							</Modal.Header>
							<Modal.Body className="modal-bim-body">
								<div className="modal-bim-content">
									<p className={classes.subTitle}>{t(transPage + 'invoice_information')}</p>
									<div className="d-flex mb-3">
										<div className={["col-md-5 p-0", classes.modalLabel].join(" ")}>
											<p>{t(transPage + 'company_name')}</p>
											<p>{t(transPage + 'company_org')}</p>
											<p>{t(transPage + 'project_name')}</p>
											<p>{t(transPage + 'ordered_by')}</p>
											<p>{t(transPage + 'reference')}</p>
										</div>
										<div className={["col-md-7 p-0", classes.modalContent].join(" ")}>
											<p>{project?.organization?.name}</p>
											<p>{project?.organization?.number}</p>
											<p>{project?.name}</p>
											<p>{us?.email}</p>
											<input id="inv-reference" type="text" className="form-control w-75" />
										</div>
									</div>
									<p className={classes.subTitle}>{t(transPage + "agreement")}</p>
									<div className="d-flex">
										<div className={["d-flex flex-column col-md-5 p-0", classes.modalContent].join(" ")}>
											<p className="mt-2 mb-2">{t(transPage + 'start_date')}</p>
											<input type="date" id="date-from" name="date-from" className="form-control mb-3 w-75" onChange={this.checkingValue}/>

											{
												priceTemplate &&
												<>
													<p className="mb-2">{t(transPage + 'price_per_month')}</p>
													<p className="" id="price-per-month" name="price-per-month">kr {priceTemplate},-</p>
												</>
											}

										</div>
										<div className={["d-flex flex-column col-md-7 p-0", classes.modalContent].join(" ")}>
											<p className="mt-2 mb-2">{t(transPage + 'number_of_months')}</p>
											<input type="number" id="number-month" name="number-month" min="1" defaultValue="1" className="form-control mb-3 w-75" onChange={(e) => this.totalMonthChanged(e)} />

											{
												priceTemplate &&
												<>
													<p className="mb-2">{t(transPage + 'total_price')}</p>
													<p className="w-75" id="total-price" name="total-price">kr {priceTemplate * this.state.totalMonth},-</p>
												</>
											}

										</div>
									</div>
									<div className={[classes.privacy].join(" ")}>
										<input id="one" type="checkbox" className={classes.cKanim} onChange={(e) => this.privacyOnChange(e)} />
										<label htmlFor="one">
											<span></span>
											<a href="https://digibim.no/terms-privacy-policy/" target="_blank" rel="noreferrer">
												{t(transPage + "confirm_statement")}
											</a>
											<ins>
												<i>
													{t(transPage + "confirm_statement")}
												</i>
											</ins>
										</label>
									</div>
								</div>
							</Modal.Body>
							<Modal.Footer className="modal-bim-footer">
								<div className="w-100 d-flex justify-content-between">
									<button className="btn-bim btn-bim-white small bim-pd-btn" onClick={this.props.onHide}>
										{t('cancel')}
									</button>
									<button className={["btn-bim small bim-pd-btn", !canActivate && "bim-not-allowed"].join(' ')} onClick={canActivate ? this.action : undefined}>
										{t('activate')}
									</button>
								</div>
							</Modal.Footer>
						</>
					) :
						<div style={{ height: '500px' }}>
							<Loader loading={!isData} />
						</div>
					}
				</Modal>
			</>
		);
	}
}
export default withTranslation()(ActivateProject);

