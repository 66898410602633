import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import Pagination from 'react-js-pagination';
import toast from 'react-hot-toast';
import { MdDelete } from 'react-icons/md';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { withTranslation } from 'react-i18next';
import { FaArrowUp } from 'react-icons/fa';

import errorHandler from '../../lib/errorHandler';
import Context from '../../pages/Context';
import Loader from '../../components/Loader/Loader';
import ModalOrganization from '../../components/Modal/Organization/Organization';
import ModalCommon from '../../components/Modal/Common/Common';

import OrganizationApi from '../../api/OrganizationApi';

import classes from './Organizations.module.css';

class Organizations extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			Organization: [],
			isLoading: false,
			isOrganization: false,
			isOrganizationType: 'Add',
			activeOrganization: false,
			activeClient: false,

			isDelete: false,

			activePage: 1,
			limitPageData: 10,
			currentPageData: 0,
			totalCount: 0,
			dataCount: 0,

			transPage: 'owner.',
			transPageT: 'template.',
			transPageTab: 'table.',
		};
	}

	componentDidMount() {
		this.getListOrganization();
	}

	componentDidUpdate() {
		const { user, Organization, activeClient } = this.context;
		if (user && Organization && Organization.length > 0) {
			if (this.state.activeClient !== activeClient) {
				this.setState(
					{
						activeClient: activeClient,
					},
					() => this.getListOrganization()
				);
			}
		}
	}

	getListOrganization = (action = false) => {
		const LA = new OrganizationApi();
		this.setState({
			isLoading: true,
		});

		const { user, activeClient, setActiveOrganization, setOrganizations } = this.context;
		const { activePage } = this.state;

		if (user) {
			const cl = JSON.parse(user);
			const params = {
				client: cl.is_superuser ? activeClient : cl.client,
				page: activePage ? activePage : 1,
			};

			LA.getListOrganization(params)
				.then((res) => {
					if (res.data) {
						if (
							!cl.is_superuser &&
							([7, 9].includes(cl.groups[0]?.id) || ['Owner', 'Organization Manager'].includes(cl.groups[0]?.name)) &&
							res.data.results.length === 0
						) {
							setActiveOrganization(false)
						}
						if (action) {
							setOrganizations(res.data.results)
						}
						this.setState(
							{
								Organization: res.data.results,
								isLoading: false,
								dataCount: res.data.count,
							},
							() => {
								this.setPagination();
							}
						);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	setPagination = () => {
		const { Organization } = this.state;
		let totalData = Organization.length;
		let currentPageDataP;

		if (this.state.activePage === 1) {
			if (totalData >= this.state.limitPageData) {
				currentPageDataP = this.state.limitPageData;
			} else if (totalData < this.state.limitPageData) {
				currentPageDataP = totalData;
			}
		} else {
			currentPageDataP = (this.state.activePage - 1) * this.state.limitPageData + 1;
		}
		this.setState({
			currentPageData: currentPageDataP,
			totalCount: totalData,
		});
	};

	handlePageChange = (pageNumber) => {
		this.setState(
			{
				activePage: pageNumber,
			},
			() => {
				this.getListOrganization();
			}
		);
	};

	requestOrganization = () => {
		this.setState({
			isOrganization: !this.state.isOrganization,
		});
	};

	requestDelete = () => {
		this.setState({
			isDelete: !this.state.isDelete,
		});
	};

	requestOrganizationAdd = () => {
		this.setState(
			{
				activeOrganization: false,
				isOrganizationType: 'Add',
			},
			() => {
				this.requestOrganization();
			}
		);
	};

	requestOrganizationDetail = (param) => {
		this.setState(
			{
				activeOrganization: param,
				isOrganizationType: 'Update',
			},
			() => {
				this.requestOrganization();
			}
		);
	};

	requestOrganizationDelete = (param) => {
		this.setState(
			{
				activeOrganization: param,
				isOrganizationType: 'Delete',
			},
			() => {
				this.requestDelete();
			}
		);
	};

	actionOrganization = (param) => {
		const LA = new OrganizationApi();

		const { isOrganizationType, activeOrganization, activeClient  } = this.state;
		const { user, setActiveOrganization } = this.context;
		const users = JSON.parse(user);
		let clients;
		if (users.is_superuser) {
			clients = activeClient;
		} else {
			clients = users.client;
		}

		if (isOrganizationType === 'Add') {
			const data = {
				client: clients,
				name: param.name,
				number: param.number,
			};
			LA.addOrganization(data)
				.then((res) => {
					if (res.data) {
						if (
							!users.is_superuser &&
							([7, 9].includes(users.groups[0]?.id) || ['Owner', 'Organization Manager'].includes(users.groups[0]?.name)) &&
							users.organizations.length === 0
						) {
							setActiveOrganization(res.data.id)
						}
						this.getListOrganization(true);
						toast.success('Successfully create new organization', {
							duration: 3000,
							className: 'toast-notification',
						});
					}
				})
				.catch((err) => {
					console.log(err);
					errorHandler(
						err.response?.data,
						`${err.response?.status} ${err.response?.statusText} - Failed to add new organization CL${clients}/NM${param.name}/NUM${param.number}`
					);
				});
		} else if (isOrganizationType === 'Update') {
			if (activeOrganization.id) {
				const data = {
					client: clients,
					name: param.name,
					number: param.number,
				};
				LA.updateOrganization(activeOrganization.id, data)
					.then((res) => {
						if (res.data) {
							this.getListOrganization(true);
							toast.success('Successfully update organization', {
								duration: 3000,
								className: 'toast-notification',
							});
						}
					})
					.catch((err) => {
						console.log(err);
						errorHandler(
							err.response?.data,
							`${err.response?.status} ${err.response?.statusText} - Failed to update organization CL${clients}/NM${param.name}/NUM${param.number}`
						);
					});
			}
		}

		this.setState(
			{
				isOrganization: !this.state.isOrganization,
			},
			() => {
				const { getListOrganization } = this.context;
				getListOrganization();
			}
		);
	};

	deleteOrganization = () => {
		const LA = new OrganizationApi();
		const { isOrganizationType, activeOrganization } = this.state;

		if (isOrganizationType === 'Delete') {
			if (activeOrganization.id) {
				LA.deleteOrganization(activeOrganization.id)
					.then((res) => {
						if (res) {
							this.getListOrganization(true);
							toast.success('Successfully delete organization', {
								duration: 3000,
								className: 'toast-notification',
							});
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
		this.setState({
			isDelete: !this.state.isDelete,
		});
	};

	setTooltip = (html, pos, text) => {
		let content = (
			<OverlayTrigger placement={pos} overlay={<Tooltip className='content-tooltip'>{text}</Tooltip>}>
				{html}
			</OverlayTrigger>
		);
		return content;
	};

	render() {
		const { Organization, transPage, transPageT, transPageTab } = this.state;
		const { t } = this.props;

		let columnsTable;
		let isOrganization;
		if (Organization && Organization.length > 0) {
			columnsTable = [
				{
					name: t(transPage + 'organization'),
					selector: (row) => row.name,
					sortable: true,
					cell: (row) => (
						<span style={{ cursor: 'pointer' }} onClick={() => this.requestOrganizationDetail(row)}>
							{row['name']}
						</span>
					),
				},
				{
					name: t(transPage + 'organization_number'),
					selector: (row) => row.number,
					sortable: false,
					cell: (row) => <span>{row['number']}</span>,
				},
				{
					name: t(transPage + 'logo'),
					selector: (row) => row.logo,
					sortable: false,
					cell: (row) => {
						if (row.logo) {
							return <img className={classes.logo} src={row.logo} alt='' />;
						}
					},
				},
				{
					name: t(transPage + 'background_color'),
					selector: (row) => row.board_background_color,
					sortable: false,
					cell: (row) => {
						return (
							<div className='d-flex'>
								{this.setTooltip(
									<div
										className={[classes.cColor, 'me-2'].join(' ')}
										style={{ background: row.board_background_color ? row.board_background_color : '#1a4a97' }}
									></div>,
									'left',
									t(transPageT + 'menu_bar')
								)}
								{this.setTooltip(
									<div
										className={[classes.cColor, 'me-2'].join(' ')}
										style={{ background: row.site_background_color ? row.site_background_color : '#828282' }}
									></div>,
									'bottom',
									t(transPageT + 'site_information')
								)}
								{this.setTooltip(
									<div
										className={classes.cColor}
										style={{ background: row.public_background_color ? row.public_background_color : '#1a4a97' }}
									></div>,
									'right',
									t(transPageT + 'public_documentation')
								)}
							</div>
						);
					},
				},
				{
					name: t(transPage + 'content_color'),
					selector: (row) => row.board_content_color,
					sortable: false,
					cell: (row) => {
						return (
							<div className='d-flex'>
								{this.setTooltip(
									<div
										className={[classes.cColor, 'me-2'].join(' ')}
										style={{ background: row.board_content_color ? row.board_content_color : '#ffffff' }}
										alt=''
									></div>,
									'left',
									t(transPageT + 'menu_bar')
								)}
								{this.setTooltip(
									<div
										className={[classes.cColor, 'me-2'].join(' ')}
										style={{ background: row.site_content_color ? row.site_content_color : '#000000' }}
										alt=''
									></div>,
									'bottom',
									t(transPageT + 'site_information')
								)}
								{this.setTooltip(
									<div
										className={classes.cColor}
										style={{ background: row.public_content_color ? row.public_content_color : '#ffffff' }}
										alt=''
									></div>,
									'right',
									t(transPageT + 'public_documentation')
								)}
							</div>
						);
					},
				},
				{
					name: '',
					selector: (row) => row.id,
					sortable: false,
					cell: (row) => <MdDelete className={classes.icon} key={row['id']} onClick={() => this.requestOrganizationDelete(row)} />,
				},
			];
		} else {
			const { user } = this.context;
			if (user) {
				const us = JSON.parse(user);
				if (
					!us.is_superuser &&
					([7, 9].includes(us.groups[0]?.id) || ['Owner', 'Organization Manager'].includes(us.groups[0]?.name)) &&
					us.organizations.length === 0
				) {
					isOrganization = true;
				}
			}
		}

		let loader = this.state.isLoading ? classes.load_loader : '';

		return (
			<div className='main-section'>
				<div className={['content-section'].join(' ')}>
					<div className={[classes.load, loader].join(' ')}>{this.state.isLoading && <Loader loading={this.state.isLoading} />}</div>

					{!this.state.isLoading && (
						<>
							<div className={classes.content}>
								<div className='d-flex w-100 mb-3'>
									<div className='d-flex ml-auto'>
										<div className='position-relative btn-bim small bim-pd-btn' onClick={this.requestOrganizationAdd}>
											{t(transPage + 'new_organization')}
											{isOrganization && (
												<>
													<div className={classes.noOrganization}></div>
													<FaArrowUp size={38} />
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</>
					)}

					{Organization && Organization.length > 0 && !this.state.isLoading && (
						<>
							<div id='tblOrganization'>
								<DataTable
									columns={columnsTable}
									data={this.state.Organization}
									className={['table table-bordered dataTable', classes.table].join(' ')}
									role='grid'
									defaultSortField='name'
									defaultSortAsc={false}
									selectableRowsHighlight={true}
									highlightOnHover={true}
									noDataComponent={t(transPage + 'no_organization_user')}
								/>
							</div>
							<div className={classes.pagination}>
								<Pagination
									activePage={this.state.activePage}
									itemsCountPerPage={this.state.limitPageData}
									totalItemsCount={this.state.dataCount}
									pageRangeDisplayed={4}
									onChange={this.handlePageChange.bind(this)}
									itemClass='page-item'
									linkClass='page-link'
									firstPageText={t(transPageTab + 'first')}
									prevPageText={t(transPageTab + 'prev')}
									nextPageText={t(transPageTab + 'next')}
									lastPageText={t(transPageTab + 'last')}
								/>
								<div className='d-flex ml-3'>
									<span className='mt-auto mr-0 mb-auto ml-auto' style={{ font: '12px Roboto', color: '#707070' }}>
										{t(transPageTab + 'showing')} {(this.state.activePage - 1) * this.state.limitPageData + 1} {t(transPageTab + 'of')}{' '}
										{this.state.currentPageData} {t(transPageTab + 'from')} {this.state.dataCount}
									</span>
								</div>
							</div>
						</>
					)}
					{!this.state.isLoading && Organization && !Organization.length && (
						<div className='d-flex w-100 justify-content-center'>
							<span>{t(transPage + 'no_organization_user')}</span>
						</div>
					)}
				</div>

				<ModalOrganization
					show={this.state.isOrganization}
					onHide={this.requestOrganization}
					title={'Organization'}
					type={this.state.isOrganizationType}
					organization={this.state.activeOrganization}
					action={this.actionOrganization}
				/>
				<ModalCommon show={this.state.isDelete} onHide={this.requestOrganizationDelete} type='delete' action={this.deleteOrganization} />
			</div>
		);
	}
}
export default withTranslation()(Organizations);
