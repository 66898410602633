import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import InputMotion from "../../components/Input/InputMotion";
import Logo from "../../components/Logo/Logo";

import { checkInputValue } from "../../lib/Common";
import LoginApi from "../../api/LoginApi";

import classes from "./Login.module.css";
class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canLogin: false,

			isUsername: true,
			isPassword: true,
			username: true,
			password: true,

			emailDesc: "Used to log in to your user account",
			passDesc: "At least 8 characters in total",
			transPage: "sign_in_R.",
		};
	}

	componentDidMount() {
		const { t } = this.props;
		const { transPage } = this.state;

		this.setState({
			emailDesc: t(transPage + "username_required"),
			passDesc: t(transPage + "password_required"),
		});
	}

	validateForm = () => {
		const email = this.state.username;
		const password = this.state.password;
		if (email && password) {
			if (String(email).trim().length > 3 && String(email).includes("@") && String(password).trim().length > 3) {
				return true;
			} else {
				this.setState({
					isUsername: false,
					isPassword: false,
				});
				return false;
			}
		}
	};

	signIn = (e) => {
		if (e && e.preventDefault) {
			e.preventDefault();
			const loginApi = new LoginApi();
			if (!this.validateForm()) {
				return;
			}
			const data = {
				email: this.state.username.trim().toLowerCase(),
				password: this.state.password,
			};
			loginApi
				.signIn(data)
				.then((res) => {
					const dataReturn = res.data;
					if (dataReturn) {
						const us = dataReturn.user;
						let url;
						if (us.is_superuser) {
							url = "/control-panel";
						} else {
							url = "/projects";
						}
						if (!us.is_superuser && (([7, 9].includes(us.groups[0]?.id) || ['Owner', 'Organization Manager'].includes(us.groups[0]?.name))) && us.organizations.length === 0) {
							url = "/organizations"
						}
						const setData = this.props.setDataUser(dataReturn.key, dataReturn.user, dataReturn.client);
						if (setData) {
							this.props.history.replace(url);
						}
					}
				})
				.catch((err) => {
					console.log(err);

					const res = err.response;
					if (err && res) {
						// let errText = res.data.non_field_errors ? res.data.non_field_errors[0] : 'Either username or password is invalid'
						let errText = "Either username or password is invalid";
						this.setState({
							isUsername: false,
							isPassword: false,

							// emailDesc: 'Used to log in to your user account',
							passDesc: errText,
						});
					}
				});
		}
	};

	inputOnChange = (e) => {
		let email = document.getElementById("username").value;
		let password = document.getElementById("password").value;

		const htmlId = e.target.id;
		const stateValue = checkInputValue(e.target.value);

		this.setState(
			{
				[htmlId]: stateValue,
			},
			() => {
				if (email && password) {
					this.setState({
						canLogin: true,
					});
				} else {
					this.setState({
						canLogin: false,
					});
				}
			}
		);
	};

	handleKeyPress = (e) => {
		if (this.state.canLogin && e.key === "Enter") {
			this.signIn();
		}
	};

	render() {
		const { t } = this.props;
		const { transPage } = this.state;

		return (
			<>
				<Helmet>
					<title>{process.env.REACT_APP_TITLE} | Sign in</title>
				</Helmet>
				<Logo />
				<form className={classes.login} onSubmit={(e) => this.signIn(e)}>
					<div className={classes.contentLogin}>
						<h1>{t("sign_in")}</h1>

						<div className="mb-3">
							<InputMotion id="username" label={t(transPage + "username")} important={true} desc={this.state.emailDesc} status={this.state.isUsername} onChange={(e) => this.inputOnChange(e)} />
						</div>
						<div className="mb-3">
							<InputMotion id="password" label={t(transPage + "password")} type="password" important={true} desc={this.state.passDesc} onEnter={this.handleKeyPress} status={this.state.isPassword} onChange={(e) => this.inputOnChange(e)} />
						</div>

						<Link to="/forgot-password" className={classes.link}>
							{t(transPage + "forgot_password")}
						</Link>

						<button className={[classes.signIn, !this.state.canLogin && "bim-not-allowed"].join(" ")}>{t("sign_inC")}</button>

						<span>{t(transPage + "caption_cookie")}</span>

						<span className="m-auto">{t(transPage + "dont_have_account")}</span>
						<Link to="register" className={[classes.signIn, classes.register].join(" ")}>
							{t(transPage + "register")}
						</Link>
						<span className="m-auto">{t(transPage + "or")}</span>
						<a href={`${process.env.REACT_APP_WEB_URL}login`} className={[classes.signIn, 'mt-2'].join(" ")}>
							{t(transPage + "sign_in_into_board")}
						</a>
					</div>
				</form>
			</>
		);
	}
}

export default withRouter(withTranslation()(Login));
