// import emailjs from 'emailjs-com';

// ENABLE THIS if want to send an error to somewhere
function errorHandler(error, info) {
    // let d = new Date()
    // let currentDate = `${d.getDate()}-${d.toLocaleString("default", { month: "long" })}-${d.getFullYear()}`;
    // let currentTime = `${('0' + d.getHours()).substr(-2)}:${('0' + d.getMinutes()).substr(-2)}`;

    // const err = JSON.stringify(error)
    // const data = {
    //     error_from: "Digibim Admin",
    //     error_when: `date: ${currentDate}  -  time: ${currentTime}`,
    //     error: error ? (err === "{}" ? `${error.message} at ${error.stack}` : err) : "",
    //     info: !info.componentStack ? info : JSON.stringify(info.componentStack)
    // }

    // emailjs.send('service_8zl7uh4', 'template_6wcrz1d', data, 'user_dmOw7xCw2y1gPOepTaxT1')
    //     .then((result) => {
    //         console.log(result.text);
    //     }, (error) => {
    //         console.log(error.text);
    //     });
}

export default errorHandler
