import React, { Component } from 'react'
import { Modal } from "react-bootstrap"

// import classes from './Common.module.css'

export default class Common extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    onOpenModal = () => {

    }

    action = () => {
        this.props.action()
    }

    render() {
        // This modal supposed to be modal global which can use any params to describe CRUD
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="md"
                onShow={this.onOpenModal}
                className='modal-bim'
            >
                <Modal.Header closeButton className="modal-bim-header">
                    <Modal.Title> {this.props.type.toUpperCase()} </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-bim-body'>
                    <div className='modal-bim-content'>
                        <p>Are you sure?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='modal-bim-footer'>
                    <div className='w-100 d-flex justify-content-between'>
                        <button className='btn-bim btn-bim-white small bim-pd-btn' onClick={this.props.onHide}>
                            CANCEL
                        </button>
                        <button className='btn-bim small bim-pd-btn' onClick={this.action}>
                            {this.props.type.toUpperCase()}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}
