import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import Layout from '../../components/Layout/Layout'
import OrganizationS from '../../sections/Organizations/Organizations'

class Organizations extends Component {
    render() {
        const { t } = this.props;
		const transPage = 'sidebar.'
        return (
            <Layout title={t(transPage + 'organizations')}>
                <OrganizationS />
            </Layout>
        )
    }
}
export default withTranslation()(Organizations);
