import React, { Component } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Pagination from "react-js-pagination";
import toast from "react-hot-toast";
import { withTranslation } from "react-i18next";
import { VscClose } from "react-icons/vsc";

import errorHandler from "../../lib/errorHandler";
import Context from "../../pages/Context";
import Loader from "../../components/Loader/Loader";
import ModalActivateProjectCP from "../../components/Modal/ActivateProjectCP/ActivateProjectCP";
import ProjectApi from "../../api/ProjectApi";
import ActivationApi from "../../api/ActivationApi";
import OrganizationApi from "../../api/OrganizationApi";

import classes from "./ControlPanel.module.css";

class ControlPanel extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			Projects: [],
			isData: false,

			activePage: 1,
			limitPageData: 10,
			currentPageData: 0,
			totalCount: 0,
			dataCount: 0,

			isActivate: false,
			activateProject: false,
			isRequestActivate: false,

			activeOrganization: false,

			clientList: [],
			organizationList: [],
			statusList: ["Not Activated", "Request Sent", "Active", "Cancelled", "Expired"],
			filterClient: [],
			filterOrganization: [],
			filterStatus: [],

			transPageP: "project_list.",
			transPageO: "owner.",
			transPageT: "table."
		};
	}

	componentDidMount() {
		const { activeOrganization, Clients } = this.context;
		this.setState(
			{
				clientList: Clients,
				activeOrganization: activeOrganization,
			},
			() => {
				this.getOrganizations();
				this.getProjects();
			}
		);
	}

	componentDidUpdate() {
		const { activeOrganization, Clients } = this.context;
		if (this.state.activeOrganization !== activeOrganization) {
			this.setState(
				{
					activeOrganization: activeOrganization,
				},
				() => {
					this.getProjects();
				}
			);
		}

		if (Clients !== this.state.clientList) {
			this.setState({
				clientList: Clients
			})
		}
	}

	getProjects = () => {
		this.getProjectsWithFilter();
	};

	getOrganizations = (param) => {
		const OA = new OrganizationApi();
		OA.getListOrganization(param)
			.then((res) => {
				this.setState({ organizationList: res.data.results, isOrganization: true }, () => { });
			})
			.catch((err) => console.log(err));
	};

	setPagination = () => {
		const { Projects } = this.state;
		let totalData = Projects.length;
		let currentPageData;

		if (this.state.activePage === 1) {
			if (totalData >= this.state.limitPageData) {
				currentPageData = this.state.limitPageData;
			} else if (totalData < this.state.limitPageData) {
				currentPageData = totalData;
			}
		} else {
			// currentPageData = totalData - (this.state.activePage - 1) * this.state.limitPageData;
			currentPageData = (this.state.activePage - 1) * this.state.limitPageData + 1;
		}
		this.setState({
			currentPageData: currentPageData,
			totalCount: totalData,
		});
	};

	handlePageChange = (pageNumber) => {
		this.setState(
			{
				activePage: pageNumber,
			},
			() => {
				this.getProjects();
			}
		);
	};

	activateProject = (project) => {
		this.setState({
			isActivate: !this.state.isActivate,
			activateProject: !this.state.activateProject ? project : false,
		});
	};

	requestActivate = (status) => {
		const AA = new ActivationApi();
		const { activateProject } = this.state;

		if (activateProject) {
			const data = {
				status: status,
			};
			AA.changeProjectStatus(activateProject.id, data)
				.then((res) => {
					if (res) {
						toast.success(`Project ${status}`, {
							duration: 1000,
							className: "toast-notification",
						});
						this.setState({
							isActivate: !this.state.isActivate,
							activateProject: false,
						});
						this.getProjects();
					}
				})
				.catch((err) => {
					console.log(err)
					errorHandler(err.response.data, `${err.response.status} ${err.response.statusText} - Failed to activation project ${activateProject.id}`)
				});
		}
	};

	getProjectsWithFilter = () => {
		const { activeOrganization, filterStatus, filterOrganization, filterClient } = this.state;
		const { activePage } = this.state;
		const PA = new ProjectApi();

		let param;
		if (filterClient.length > 0 || filterStatus.length > 0 || filterOrganization.length > 0) {
			param = {
				organization__client__in: filterClient.map((cl) => parseInt(cl.id)),
				organization__in: filterOrganization.map((org) => parseInt(org.id)),
				status__in: filterStatus,
				page: activePage ? activePage : 1,
			};
		} else {
			param = {
				organization: activeOrganization ? activeOrganization : "",
				page: activePage ? activePage : 1,
			};
		}

		if (activeOrganization) {
			PA.getProjects(param)
				.then((res) => {
					this.setState({ Projects: res.data.results, isData: true, dataCount: res.data.count }, () => {
						this.setPagination();
					});
				})
				.catch((err) => console.log(err));
		} else {
			this.setState({
				Projects: [],
				isData: true,
			});
		}
	};

	onChangeFilter = (e, param) => {
		const val = e.target.value;

		if (val && val !== "x") {
			const data = this.checkingFilter(param);
			let arrData = data.arrData;
			let stateVal = data.stateVal;

			if (param === "status") {
				if (arrData.indexOf(val) === -1) {
					arrData.push(val);
				}
			} else {
				const nm = e.target.options[e.target.selectedIndex].text;
				const isExist = arrData.findIndex((p) => p.id === e.target.value);
				if (isExist === -1) {
					arrData.push({
						id: val,
						name: nm,
					});
				}
			}

			this.setState(
				{
					[stateVal]: arrData,
				},
				() => {
					if (param === "client" && this.state.filterClient.length){
						const param ={
							client__in: this.state.filterClient.map(f=> parseInt(f.id))
						}
						this.getOrganizations(param)
					}
					this.getProjectsWithFilter();

				}
			);
		}
	};

	checkingFilter = (param) => {
		let arrData = [];
		let stateVal;
		const { filterStatus, filterOrganization, filterClient } = this.state;

		switch (param) {
			case "status":
				arrData = [...filterStatus];
				stateVal = "filterStatus";
				break;
			case "organization":
				arrData = [...filterOrganization];
				stateVal = "filterOrganization";
				break;
			case "client":
				arrData = [...filterClient];
				stateVal = "filterClient";
				break;
			default:
				break;
		}

		return { arrData, stateVal };
	};

	removeFilter = (param, value) => {
		const data = this.checkingFilter(param);
		let arrData = data.arrData;
		let stateVal = data.stateVal;
		if (value) {
			let filtered;
			if (param === "status") {
				filtered = arrData.filter((val) => {
					return val !== value;
				});
			} else {
				filtered = arrData.filter((val) => {
					return val.id !== value;
				});
			}
			this.setState(
				{
					[stateVal]: filtered,
				},
				() => {
					this.getProjectsWithFilter();
				}
			);
		}
	};

	render() {
		const { isData, Projects, transPageP, transPageO, transPageT, clientList, organizationList, statusList, filterClient, filterOrganization, filterStatus } = this.state;
		const { t } = this.props;

		const columnsTable = [
			{
				name: t(transPageP + "project_name"),
				selector: (row) => row.name,
				sortable: true,
				cell: (row) => {
					return <Link to={`/project/${row.id}`}>{row.name}</Link>
				},
			},
			{
				name: t(transPageP + "project_number"),
				selector: (row) => row.number,
				sortable: true,
			},
			{
				name: t(transPageO + "organization"),
				selector: (row) => row.organization_detail.name,
				sortable: true,
				cell: (row) => {
					return row.organization_detail?.name;
				},
			},
			{
				name: t(transPageO + "organization_number"),
				selector: (row) => row.organization_detail.number,
				sortable: true,
				cell: (row) => {
					return row.organization_detail?.number;
				},
			},
			{
				name: t(transPageP + "status"),
				selector: (row) => row.status,
				sortable: true,
				cell: (row) => {
					let styles;
					switch (row['status']) {
						case "Not Activated":
							styles = classes.statusNotActivated;
							break;
						case "Request Sent":
							styles = classes.statusDefault;
							break;
						case "Active":
							styles = classes.statusDefault;
							break;
						case "Expired":
							styles = classes.statusExpired;
							break;
						case "Cancelled":
							styles = classes.statusExpired;
							break;
						default:
							styles = classes.statusDefault;
							break;
					}
					return <div className={styles}>{row['status']}</div>;
				},
			},
			{
				name: "",
				selector: (row) => row.status,
				sortable: true,
				cell: (row) => {
					let html;
					if (row) {
						const stat = row.status;
						if (stat === "Not Activated") {
							html = <span>-</span>;
						} else if (stat === "Request Sent") {
							html = (
								<button className="btn-bim small blue " onClick={() => this.activateProject(row)}>
									<small>Activate</small>
								</button>
							);
						} else if (stat.includes("Active until")) {
							html = (
								<button className="btn-bim small red" onClick={() => this.activateProject(row)}>
									<small>Deactivate</small>
								</button>
							);
						} else if (stat === "Expired") {
							html = (
								<button className="btn-bim small blue" onClick={() => this.activateProject(row)}>
									<small>Activate</small>
								</button>
							);
						} else if (stat === "Cancelled") {
							html = (
								<button className="btn-bim small blue" onClick={() => this.activateProject(row)}>
									<small>Activate</small>
								</button>
							);
						}
					} else {
						html = <></>;
					}
					return html;
				},
			},
		];

		let html;
		if (!isData) {
			html = (
				<div className="section-loader">
					<Loader loading={!isData} />
				</div>
			);
		} else {
			if (Projects) {
				html = (
					<>
						<div className={classes.filterTable}>
							<div className="d-flex">
								{(Projects) && (clientList && clientList.length > 0) && (
									<div className={classes.filterHtml}>
										<div className="d-flex align-items-center">
											<label htmlFor="clientFilter">{t(transPageP + "clients")}</label>
											<select onChange={(e) => this.onChangeFilter(e, "client")}>
												<option value="x">---</option>
												{clientList.map((sl, i) => {
													return (
														<option key={`clientFilter-${sl.name}-${i}`} value={sl.id}>
															{sl.name}
														</option>
													);
												})}
											</select>
										</div>

										<div className={classes.filterSet}>
											{filterClient &&
												filterClient.length > 0 &&
												filterClient.map((fo) => {
													return (
														<div className={classes.ftSelected} key={`filterClient${fo.id}`}>
															{fo.name} <VscClose onClick={() => this.removeFilter("client", fo.id)} />
														</div>
													);
												})}
										</div>
									</div>
								)}

								{(Projects) && (organizationList && organizationList.length > 0) && (
									<div className={classes.filterHtml}>
										<div className="d-flex align-items-center">
											<label htmlFor="organizationFilter">{t(transPageP + "organization")}</label>
											<select onChange={(e) => this.onChangeFilter(e, "organization")}>
												<option value="x">---</option>
												{organizationList.map((sl, i) => {
													return (
														<option key={`organizationFilter-${sl.name}-${i}`} value={sl.id}>
															{sl.name}
														</option>
													);
												})}
											</select>
										</div>

										<div className={classes.filterSet}>
											{filterOrganization &&
												filterOrganization.length > 0 &&
												filterOrganization.map((fo) => {
													return (
														<div className={classes.ftSelected} key={`organizationt${fo.id}`}>
															{fo.name} <VscClose onClick={() => this.removeFilter("organization", fo.id)} />
														</div>
													);
												})}
										</div>
									</div>
								)}
							</div>

							{(Projects) && (
								<div className={classes.filterHtml}>
									<div className="d-flex align-items-center">
										<label htmlFor="statusFilter">{t(transPageP + "status")}</label>
										<select onChange={(e) => this.onChangeFilter(e, "status")}>
											<option value="x">---</option>
											{statusList.map((sl, i) => {
												return (
													<option key={`statusFilter-${sl}-${i}`} value={sl}>
														{sl}
													</option>
												);
											})}
										</select>
									</div>

									<div className={classes.filterSet}>
										{filterStatus &&
											filterStatus.length > 0 &&
											filterStatus.map((fs) => {
												return (
													<div className={classes.ftSelected} key={`statust${fs}`}>
														{fs} <VscClose onClick={() => this.removeFilter("status", fs)} />
													</div>
												);
											})}
									</div>
								</div>
							)}
						</div>
						<DataTable columns={columnsTable} data={this.state.Projects} className={["table table-bordered dataTable", classes.table].join(" ")} role="grid" defaultSortField="manager" defaultSortAsc={false} selectableRowsHighlight={true} highlightOnHover={true} noDataComponent="This Organization doesn't have any project" />
						<div className={classes.pagination}>
							<Pagination
								activePage={this.state.activePage}
								itemsCountPerPage={this.state.limitPageData}
								totalItemsCount={this.state.dataCount}
								pageRangeDisplayed={4}
								onChange={this.handlePageChange.bind(this)}
								itemClass="page-item"
								linkClass="page-link"
								firstPageText={t(transPageT + "first")}
								prevPageText={t(transPageT + "prev")}
								nextPageText={t(transPageT + "next")}
								lastPageText={t(transPageT + "last")}
							/>
							<div className="d-flex ml-3">
								<span className="mt-auto mr-0 mb-auto ml-auto" style={{ font: "12px Roboto", color: "#707070" }}>
									{t(transPageT + "showing")} {(this.state.activePage - 1) * this.state.limitPageData + 1} {t(transPageT + "of")} {this.state.currentPageData} {t(transPageT + "from")} {this.state.dataCount}
								</span>
							</div>
						</div>
					</>
				);
			}
		}
		return (
			<>
				<div className="main-section">
					<div className="content-section">{html}</div>
				</div>
				<ModalActivateProjectCP
					show={this.state.isActivate}
					onHide={() => {
						this.setState({
							isActivate: !this.state.isActivate,
							activateProject: false,
						});
					}}
					project={this.state.activateProject}
					action={this.requestActivate}
				/>
			</>
		);
	}
}
export default withTranslation()(ControlPanel);
