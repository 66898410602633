import React, { Component } from 'react'
import errorHandler from '../../lib/errorHandler';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError = error => {
        return { hasError: true };
    };

    componentDidCatch(error, info) {
        if (error) {
            this.setState({
                hasError: true
            }, () => {
                errorHandler(error, info)
            })
        }
    }

    render() {
        if (this.state.hasError) {
            return <>
            </>
        }
        return this.props.children
    }
}
