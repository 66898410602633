import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { MdDelete, MdEdit } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import Pagination from 'react-js-pagination';
import toast from 'react-hot-toast';

import Layout from '../../components/Layout/Layout';
import ModalCommon from '../../components/Modal/Common/Common';
import ModalAsset from '../../components/Modal/Asset/Asset';
import Context from '../Context';

import AssetsApi from '../../api/AssetsApi';

import classes from './Assets.module.css';
import Loader from '../../components/Loader/Loader';
import errorHandler from '../../lib/errorHandler';

const transPage = 'assets.';
const transPageT = 'table.';
class Assets extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			data: null,

			activePage: 1,
			limitPageData: 10,
			currentPageData: 0,
			totalCount: 0,
			dataCount: 0,

			openModal: false,
			selectedImage: null,
			isDeleteAsset: false,

			activeClient: false,
			isInitialize: false,
		};
	}
	setClientAndGetListAsset(activeClient) {
		this.setState(
			{
				activeClient: activeClient,
			},
			() => {
				this.getListAssets();
			}
		);
	}
	componentDidMount() {
		const { user, activeClient } = this.context;
		if (!this.state.isInitialize && !this.state.data && user) {
			this.setState(
				{
					isInitialize: true,
				},
				() => this.setClientAndGetListAsset(activeClient)
			);
		}
	}
	componentDidUpdate() {
		const { user, activeClient } = this.context;
		let objUser = JSON.parse(user);

		if (
			!this.state.isInitialize &&
			!this.state.data &&
			user &&
			((objUser.is_superuser && activeClient) || (!objUser.is_superuser && !activeClient))
		) {
			this.setState(
				{
					isInitialize: true,
				},
				() => this.setClientAndGetListAsset(activeClient)
			);
		}

		if (activeClient && this.state.activeClient && this.state.activeClient !== activeClient) {
			this.setClientAndGetListAsset(activeClient);
		}
	}
	getListAssets = () => {
		const { activePage } = this.state;
		const { activeClient } = this.context;

		let params = {
			client: activeClient,
			is_default: false,
			page: activePage ? activePage : 1,
		};

		if (!activeClient) delete params.client;

		const assetApi = new AssetsApi();
		assetApi
			.getAssets(params)
			.then((res) => {
				this.setState(
					{
						data: res.data.results,
						dataCount: res.data.count,
					},
					() => {
						this.setPagination();
					}
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	setPagination = () => {
		const { data } = this.state;
		let totalData = data?.length;
		let currentPageData;

		if (this.state.activePage === 1) {
			if (totalData >= this.state.limitPageData) {
				currentPageData = this.state.limitPageData;
			} else if (totalData < this.state.limitPageData) {
				currentPageData = totalData;
			}
		} else {
			currentPageData = (this.state.activePage - 1) * this.state.limitPageData + 1;
		}
		this.setState({
			currentPageData: currentPageData,
			totalCount: totalData,
		});
	};
	handlePageChange = (pageNumber) => {
		const { activeClient } = this.state;
		this.setState(
			{
				activePage: pageNumber,
			},
			() => {
				if (activeClient) {
					this.getListAssets(activeClient);
				}
			}
		);
	};
	onAddAsset = (data, id) => {
		const { user, activeClient } = this.context;
		let objUser = JSON.parse(user);

		if (user) {
			const clientId = objUser.is_superuser ? activeClient || objUser.client : objUser.client
			const dataAsset = data
			if (clientId) dataAsset.client = clientId

			const assetApi = new AssetsApi();
			if (!id) {
				assetApi
					.addAsset(dataAsset)
					.then((res) => {
						if (res) {
							toast.success('New Asset successfully added', {
								duration: 1000,
								className: 'toast-notification',
							});
							this.getListAssets();
						}
					})
					.catch((err) => {
						console.log(err);
						errorHandler(err.response.data, `${err.response.status} ${err.response.statusText} - Failed to add new asset`);
					});
			} else {
				assetApi
					.updateAsset(id, data)
					.then((res) => {
						if (res) {
							toast.success('Successfully Update asset ', {
								duration: 1000,
								className: 'toast-notification',
							});
							this.getListAssets();
						}
					})
					.catch((err) => {
						console.log(err);
						errorHandler(err.response.data, `${err.response.status} ${err.response.statusText} - Failed to add new asset`);
					});
			}
		}
	};
	onDeleteAsset = () => {
		if (this.state.data) {
			const assetApi = new AssetsApi();

			assetApi
				.deleteAsset(this.state.selectedImage.id)
				.then((res) => {
					if (res) {
						this.setState(
							{
								selectedImage: null,
								isDeleteAsset: false,
							},
							() => {
								toast.success('Delete successfully', {
									duration: 1000,
									className: 'toast-notification',
								});
								this.getListAssets();
							}
						);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	render() {
		const { t } = this.props;

		const columnsTable = [
			{
				name: t(transPage + 'image'),
				selector: (row) => row.asset_file,
				sortable: false,
				cell: (row) => {
					const imageUrl = row.file;
					return (
						<div className={classes.imgWrapper}>
							<img src={imageUrl} alt='' loading='lazy' />
						</div>
					);
				},
			},
			{
				name: t(transPage + 'file_name'),
				selector: (row) => row.name,
				sortable: false,
				cell: (row) => {
					return <span>{row.name}</span>;
				},
			},
			{
				name: '',
				selector: (row) => row.id,
				sortable: false,
				width: '112px',
				cell: (row, index) => (
					<div className='d-flex'>
						<MdEdit
							className={[classes.icon, 'me-3'].join(' ')}
							onClick={() => {
								this.setState({
									selectedImage: { row, index },
									openModal: true,
								});
							}}
						/>
						<MdDelete
							className={classes.icon}
							onClick={() => {
								this.setState({
									selectedImage: row,
									isDeleteAsset: true,
								});
							}}
						/>
					</div>
				),
			},
		];

		return (
			<Layout title={t(transPage + 'title')}>
				<div className='main-section'>
					<div className={['content-section', classes.contentSection].join(' ')}>
						{this.state.data ? (
							<>
								<div className={classes.content}>
									<div className='d-flex w-100 mb-3'>
										<div className='d-flex ml-auto'>
											<button className='btn-bim small bim-pd-btn' onClick={() => this.setState({ openModal: true })}>
												{t(transPage + 'new_asset')}
											</button>
										</div>
									</div>
								</div>
								<DataTable
									columns={columnsTable}
									data={this.state.data}
									className={['table table-bordered dataTable', classes.table].join(' ')}
									role='grid'
									defaultSortField='id'
									defaultSortAsc={false}
									selectableRowsHighlight={true}
									highlightOnHover={true}
									noDataComponent={t(transPage + 'no_data')}
								/>
								<div className={classes.pagination}>
									<Pagination
										activePage={this.state.activePage}
										itemsCountPerPage={this.state.limitPageData}
										totalItemsCount={this.state.dataCount}
										pageRangeDisplayed={4}
										onChange={this.handlePageChange.bind(this)}
										itemClass='page-item'
										linkClass='page-link'
										firstPageText={t(transPageT + 'first')}
										prevPageText={t(transPageT + 'prev')}
										nextPageText={t(transPageT + 'next')}
										lastPageText={t(transPageT + 'last')}
									/>
									<div className='d-flex ml-3'>
										<span className='mt-auto mr-0 mb-auto ml-auto' style={{ font: '12px Roboto', color: '#707070' }}>
											{t(transPageT + 'showing')} {(this.state.activePage - 1) * this.state.limitPageData + 1} {t(transPageT + 'of')}{' '}
											{this.state.currentPageData} {t(transPageT + 'from')} {this.state.dataCount}
										</span>
									</div>
								</div>
							</>
						) : (
							<div className='section-loader'>
								<Loader loading={!this.state.data} />
							</div>
						)}
					</div>
				</div>
				<ModalAsset
					show={!!this.state.openModal}
					onHide={() => {
						this.setState({
							openModal: false,
							selectedImage: null,
						});
					}}
					action={this.onAddAsset}
					selectedImage={this.state.selectedImage}
				/>
				<ModalCommon
					show={this.state.isDeleteAsset}
					onHide={() =>
						this.setState({
							isDeleteAsset: !this.state.isDeleteAsset,
						})
					}
					type='delete'
					action={this.onDeleteAsset}
				/>
			</Layout>
		);
	}
}
export default withTranslation()(Assets);
