import React from 'react'
import { BsPlus, BsTrash } from "react-icons/bs"
import { FaEdit } from "react-icons/fa"
import { withTranslation } from 'react-i18next';

function Button(props) {
    let icon
    switch (props.icon) {
        case 'plus':
            icon = <BsPlus />
            break;
        case 'delete':
            icon = <BsTrash />
            break;
        case 'update':
            icon = <FaEdit />
            break;

        default:
            icon = ""
            break;
    }
    let isDisabled = props.isDisable ? "bim-not-allowed" : ""

    const { t } = props;
    return (
        <div className={['btn-bim small bim-pd-btn', props.style, isDisabled].join(' ')} onClick={!props.isDisable ? props.onClick : undefined}>
            {icon} {t('project_list.new_projectC')}
        </div>
    )
}
export default withTranslation()(Button);

