import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import classes from './Error.module.css'

export default class Error extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: [
                { id: 400, title: "Bad Request.", detail: "Http Error 400. The request is badly formed." },
                { id: 401, title: "No authorization found.", detail: "This page is not publically available. To access it please login first." },
                { id: 403, title: "Forbidden.", detail: "You don't have permission to access on this server." },
                { id: 404, title: "Oops! Sorry page does not found", detail: "" },
                { id: 500, title: "Internal Server Error.", detail: "Sorry for the inconvenience, we're working on it." },
            ],
            backLink: "/",
            err: 404
        };

    }
    componentDidMount() {
        let params = this.props.id
        if (params) {
            this.setState({
                err: params
            })
        } else {
            this.setState({
                err: false
            })
        }

    }
    render() {
        const { error } = this.state
        let content
        if (this.state.err) {
            error.forEach((s, i) => {
                let title, detail
                if (s.id === parseInt(this.state.err)) {
                    detail = s.detail !== "" ? <p className={classes.detail}>{s.detail}</p> : <></>
                    title = <p className={classes.text}>{s.title}</p>
                    content =
                        <div className={classes.c_err}>
                            <div className={classes.boxLeft}>
                                <div className={classes.boxNum}>
                                    <p className={classes.num}>{this.state.err}</p>
                                </div>
                                {title}
                                {detail}
                                <Link className={classes.customLink} to={'/'}>
                                    <div className={classes.btnCustom}>
                                        <span>Home</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                }
            })
        } else {
            content =
                <div className={classes.c_err}>
                    <div className={classes.boxLeft}>
                        <div className={classes.boxNum}>
                            <p className={classes.num}>404</p>
                        </div>
                        <p className={classes.text}>Oops! Sorry page does not found</p>
                        <Link className={classes.customLink} to={'/'}>
                            <div className={classes.btnCustom}>
                                <span>Home</span>
                            </div>
                        </Link>
                    </div>
                </div>
        }
        return (
            <div className={[classes.customContainer].join(' ')}>
                {content}
            </div>
        )
    }
}
