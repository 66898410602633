import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import { VscClose } from 'react-icons/vsc';

import classes from './Asset.module.css';
// import { debounce } from '../../../lib/Common';

const transPage = "assets."

export default function Asset(props) {
	const { show, onHide, action, selectedImage } = props;
	const { t } = useTranslation();
	const [thumbsImg, setThumbsImg] = useState(null);
	const [fileName, setFileName] = useState('');

	const onCloseModal = () => {
		setThumbsImg(null);
		setFileName('');
		onHide();
	};
	const onOpenModal = () => {
		if (selectedImage) {
			const { row } = selectedImage;
			if (!row) return;
			const { file } = row;

			if (file) {
                const af = file;
                if (af) {
                    // const origin = af.substring(0, af.indexOf('?'));
                    // const baseName = origin.slice(origin.lastIndexOf('/') + 1);
                    setThumbsImg(af);
					// setFileName(baseName.substring(0, baseName.indexOf('.')));
					setFileName(row.name);
				}
			}
		}
	};
	const onDrop = (files) => {
		// setImgUrl('');
		setThumbsImg(files[0]);
		setFileName(files[0].name);
	};
	const removeDroppedFile = (e) => {
		e.stopPropagation();
		setThumbsImg(null);
		setFileName('');
	};

	const save = () => {
        if (selectedImage && thumbsImg && typeof thumbsImg === "string") {
            onCloseModal();
            return;
        }
		const data = {};
        if (thumbsImg) data.file = thumbsImg;
        if (fileName) data.name = fileName.substring(0, fileName.lastIndexOf('.'))
		action(data, selectedImage ? selectedImage?.row.id : null);
		onCloseModal();
	};

	const imgPreview = thumbsImg ? (
		<div className={classes.thumb}>
			<VscClose onClick={removeDroppedFile} />
			<img src={typeof thumbsImg === 'string' ? thumbsImg : URL.createObjectURL(thumbsImg)} className={classes.img} alt='' />
		</div>
	) : null;

	return (
		<Modal show={show} onHide={onCloseModal} size='md' onShow={onOpenModal} className='modal-bim'>
			<Modal.Header closeButton className='modal-bim-header'>
				<Modal.Title>{t(transPage + 'new_asset')} </Modal.Title>
			</Modal.Header>
			<Modal.Body className='modal-bim-body'>
				<div className='modal-bim-content'>
					<div className={classes.modalContent}>
						<div className='d-flex flex-column position-relative'>
							<Dropzone onDrop={(files) => onDrop(files)} accept='image/*' maxFiles={1} multiple={false}>
								{({ getRootProps, getInputProps }) => (
									<section className={classes.contentImageSlider}>
										<div {...getRootProps({ className: ['dropzone', classes.dropzone, classes.dropzoneImgSlider].join(' ') })}>
											<input {...getInputProps()} />
											{imgPreview ? imgPreview : <button className='btn-bim small'>browse</button>}
										</div>
									</section>
								)}
							</Dropzone>
							<p className={classes.imgFileName}>{fileName}</p>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-bim-footer'>
				<div className='w-100 d-flex justify-content-between'>
					<button className='btn-bim btn-bim-white small bim-pd-btn' onClick={onCloseModal}>
						{t('cancel')}
					</button>
					<button className='btn-bim small bim-pd-btn' onClick={save} disabled={!thumbsImg}>
						{t('save')}
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}
