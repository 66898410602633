import axios from "axios";
import config from "../lib/Config";
class OrgManagerApi {
	base_api = config.base_api;
	token = config.token;

	addOrgManager(data) {
		return axios.post(this.base_api + `organization_managers/`, data, {
			params: {},
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	getListOrgManagers(params) {
		return axios.get(this.base_api + `organization_managers/`, {
			params: params,
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	getOrgManagerDetails(id) {
		return axios.get(this.base_api + `organization_managers/${id}/`, {
			params: {},
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	updateOrgManager(managerId, data){
        return axios.patch(this.base_api + `organization_managers/${managerId}/`, data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
	}

	deleteOrgManager(managerId){
        return axios.delete(this.base_api + `organization_managers/${managerId}/`,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
	}
	
}

export default OrgManagerApi;
