import React, { Component } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/react";

export default class Loader extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const override = css`
			display: block;
			margin: 0 auto;
			border-color: transparent;
		`;
        let size = this.props.size ? this.props.size : 150
        let color = this.props.color ? this.props.color : "#17438b"
		return (
			<div className="is-loader">
				<FadeLoader color={color} loading={this.props.loading} css={override} size={size} />
			</div>
		);
	}
}
