import axios from "axios";
import config from "../lib/Config";
class LoginApi {
  base_api = config.base_api;
  token = config.token;

  signIn(data) {
    return axios.post(this.base_api + `admin-login/`, data, {
      params: {

      }
    })
  }
  
  signOut() {
    return axios.post(this.base_api + `admin-logout/`, {}, {
      headers: {
        Authorization: "Token " + this.token,
      }
    })
  }

}

export default LoginApi;
