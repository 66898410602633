import axios from 'axios';
import qs from 'qs';
import config from '../lib/Config';
class ProjectApi {
	base_api = config.base_api;
	token = config.token;

	// getProjects(params) {
	// 	return axios.get(this.base_api + `projects/`, {
	// 		params: params,
	// 		headers: {
	// 			Authorization: "Token " + this.token,
	// 			"Content-Type": "application/json",
	// 		},
	// 	});
	// }
	getProjects(params) {
		return axios.get(this.base_api + `projects/`, {
			params: params,
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: 'comma' });
			},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	createProject(data) {
		var bodyFormData = new FormData();

		bodyFormData.append('client', data.client);
		bodyFormData.append('template', data.template);

		if (data.organization) {
			bodyFormData.append('organization', data.organization);
		}

		bodyFormData.append('name', data.name);
		bodyFormData.append('number', data.number);

		if (data.manager) {
			bodyFormData.append('manager', data.manager);
		}

		if (data.managers && data.managers.length) {
			for (const key in data.managers) {
				bodyFormData.append('managers', data.managers[key]);
			}
		}

		bodyFormData.append('password', data.password);

		for (let i = 0; i < data.text_contents.length; i++) {
			bodyFormData.append(`text_contents[${i}]text_holder`, data.text_contents[i].text_holder);
			bodyFormData.append(`text_contents[${i}]language`, data.text_contents[i].language);
			bodyFormData.append(`text_contents[${i}]content`, data.text_contents[i].content);
			bodyFormData.append(`text_contents[${i}]is_hidden`, data.text_contents[i].is_hidden);
		}

		if (data.editable_text_holders.length > 0) {
			for (let i = 0; i < data.editable_text_holders.length; i++) {
				bodyFormData.append(`editable_text_holders[${i}]text_holder_language`, data.editable_text_holders[i].text_holder_language);
				bodyFormData.append(`editable_text_holders[${i}]name`, data.editable_text_holders[i].name);
			}
		}

		if (data.assets.length > 0) {
			for (let j = 0; j < data.assets.length; j++) {
				bodyFormData.append(`assets[${j}]section`, data.assets[j].section);
				bodyFormData.append(`assets[${j}]asset_link`, data.assets[j].asset_link);
				if (data.assets[j].asset_file && typeof data.assets[j].asset_file === 'object') {
					bodyFormData.append(`assets[${j}]asset_file`, data.assets[j].asset_file);
				}
			}
		}

		if (data.textIcons?.length) {
			for (let i = 0; i < data.textIcons.length; i++) {
				bodyFormData.append(`text_icons[${i}]text_holder`, data.textIcons[i].text_holder);
				bodyFormData.append(`text_icons[${i}]icon`, data.textIcons[i].icon);
				bodyFormData.append(`text_icons[${i}]language`, data.textIcons[i].language);
			}
		}

		if (data.location) {
			const loc = data.location;
			if (loc.address) {
				bodyFormData.append('location_name', loc.address);
			}
			if (loc.address) {
				bodyFormData.append('latitude', loc.lat);
			}
			if (loc.address) {
				bodyFormData.append('longitude', loc.lng);
			}
		}

		return axios.post(this.base_api + `projects/`, bodyFormData, {
			params: {},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	getProjectsDetail(id) {
		return axios.get(this.base_api + `projects/${id}/`, {
			params: {},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	updateProject(projectId, data) {
		return axios.patch(this.base_api + `projects/${projectId}/`, data, {
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	updateProjectSection(projectId, data) {
		var bodyFormData = new FormData();

		if (data.text_contents) {
			for (let i = 0; i < data.text_contents.length; i++) {
				if (data.text_contents[i].id) {
					bodyFormData.append(`text_contents[${i}]id`, data.text_contents[i].id);
				}
				// bodyFormData.append(`text_contents[${i}]project`, data.text_contents[i].project);

				bodyFormData.append(`text_contents[${i}]text_holder`, data.text_contents[i].text_holder);
				bodyFormData.append(`text_contents[${i}]language`, data.text_contents[i].language);
				bodyFormData.append(`text_contents[${i}]content`, data.text_contents[i].content);
				bodyFormData.append(`text_contents[${i}]is_hidden`, data.text_contents[i].is_hidden);
			}
		}
		if (data.editable_text_holders) {
			for (let i = 0; i < data.editable_text_holders.length; i++) {
				bodyFormData.append(`editable_text_holders[${i}]text_holder_language`, data.editable_text_holders[i].text_holder_language);
				bodyFormData.append(`editable_text_holders[${i}]name`, data.editable_text_holders[i].name);
			}
		}
		if (data.assets) {
			for (let j = 0; j < data.assets.length; j++) {
				if (data.assets[j].status === 'add') {
					bodyFormData.append(`assets[${j}]project`, data.assets[j].project);
					bodyFormData.append(`assets[${j}]section`, data.assets[j].section);
					if (data.assets[j].asset_file || typeof data.assets[j].asset_file === 'object') {
						bodyFormData.append(`assets[${j}]asset_file`, data.assets[j].asset_file);
					} else if (!data.assets[j].asset_file) {
						bodyFormData.append(`assets[${j}]asset_file`, '');
					}
					bodyFormData.append(`assets[${j}]asset_link`, data.assets[j].asset_link);
				} else if (data.assets[j].status === 'update') {
					bodyFormData.append(`assets[${j}]id`, data.assets[j].id);
					bodyFormData.append(`assets[${j}]section`, data.assets[j].section);
					if (data.assets[j].asset_file && typeof data.assets[j].asset_file === 'object') {
						bodyFormData.append(`assets[${j}]asset_file`, data.assets[j].asset_file);
					} else {
						if (!data.assets[j].asset_file) {
							bodyFormData.append(`assets[${j}]asset_file`, data.assets[j].asset_file);
						}
					}
					bodyFormData.append(`assets[${j}]asset_link`, data.assets[j].asset_link);
				}
			}
		}
		if (data.textIcons?.length) {
			for (let i = 0; i < data.textIcons.length; i++) {
				bodyFormData.append(`text_icons[${i}]text_holder`, data.textIcons[i].text_holder);
				bodyFormData.append(`text_icons[${i}]icon`, data.textIcons[i].icon);
				bodyFormData.append(`text_icons[${i}]language`, data.textIcons[i].language);
			}
		}

		return axios.patch(this.base_api + `projects/${projectId}/`, bodyFormData, {
			params: {},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	deleteProject(projectId) {
		return axios.delete(this.base_api + `projects/${projectId}/`, {
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	copyProject(data) {
		return axios.post(this.base_api + `projects/copy_project/`, data, {
			params: {},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	getProjectMessages(params) {
		return axios.get(this.base_api + `messages/`, {
			params: params,
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	createProjectMessage(data) {
		var bodyFormData = new FormData();

		if (data) {
			bodyFormData.append(`project`, data.project);
			bodyFormData.append(`user`, data.user);
			bodyFormData.append(`subject`, data.subject);
			bodyFormData.append(`content`, data.content);
			bodyFormData.append(`link`, data.link);
			bodyFormData.append(`expiration_date`, data.expiration_date);

			if (data.file) {
				bodyFormData.append(`file`, data.file);
			}
		}

		return axios.post(this.base_api + `messages/`, bodyFormData, {
			params: {},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	deleteProjectMessage(messageId) {
		return axios.delete(this.base_api + `messages/${messageId}/`, {
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	deleteProjectLanguage(projectId, data) {
		return axios.delete(this.base_api + `projects/${projectId}/delete_language/`, {
			data,
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	activateProject(data) {
		return axios.post(this.base_api + `project_activations/`, data, {
			params: {},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	getTokenBoardProject(projectId, param) {
		let url;
		if (param === 'board') {
			url = this.base_api + `projects/${projectId}/get_board_token/`;
		} else {
			url = this.base_api + `projects/${projectId}/preview_board/`;
		}
		return axios.get(url, {
			params: {},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	sendBoardLinktoManager(projectId) {
		return axios.get(this.base_api + `projects/${projectId}/send_board_link/`, {
			params: {},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	pinProjectMessage(messageId, data) {
		return axios.patch(this.base_api + `messages/${messageId}/`, data, {
			params: {},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	updateProjectMessage(idMessage, data) {
		var bodyFormData = new FormData();

		if (data) {
			bodyFormData.append(`user`, data.user);
			bodyFormData.append(`subject`, data.subject);
			bodyFormData.append(`content`, data.content);
			bodyFormData.append(`link`, data.link);
			bodyFormData.append(`expiration_date`, data.expiration_date);

			if (data.file) {
				bodyFormData.append(`file`, data.file);
			}
		}

		return axios.patch(this.base_api + `messages/${idMessage}/`, bodyFormData, {
			params: {},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	getReport(params) {
		return axios.get(this.base_api + `client_reports/`, {
			params: params,
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}
}

export default ProjectApi;
