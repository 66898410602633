import Cookies from 'js-cookie';

export const setCookie = (key, dataUser, client) => {
	Cookies.set('key', key);
	Cookies.set('user', dataUser);
	Cookies.set('client', client);
};

export const removeCookie = () => {
	Cookies.remove('key');
	Cookies.remove('user');
	Cookies.remove('client');
	localStorage.removeItem('digibim-admin-org');
};

export const checkInputValue = (value) => {
	let isVal = value ? value : false;
	return isVal;
};

export const debounce = (func, delay) => {
	let timer;
	return function (...args) {
		clearTimeout(timer);
		timer = setTimeout(() => func.apply(this, args), delay);
	};
};
