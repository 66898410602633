import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Toaster } from 'react-hot-toast';

import { LoadScript } from '@react-google-maps/api';

import errorHandler from './lib/errorHandler';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Site from './pages/Site/Site';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';

import config, { setArr } from './lib/Config.js';
import Context from './pages/Context';
import OrganizationApi from './api/OrganizationApi';
import ClientApi from './api/ClientApi';

import { setCookie, removeCookie } from './lib/Common';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gMapApiKey: process.env.REACT_APP_MAP_KEY,
			mapsLib: ['drawing', 'places'],

			client: Cookies.get('client'),
			userKey: Cookies.get('key'),
			user: Cookies.get('user') ? Cookies.get('user') : false,
			isSuperUser: Cookies.get('user') ? JSON.parse(Cookies.get('user')).is_superuser : false,
			isGroups: Cookies.get('user') ? JSON.parse(Cookies.get('user')).groups : false,
			removeDataUser: () => {
				this.removeDataUser();
			},

			getListOrganization: () => {
				this.getListOrganization();
			},
			Organization: false,
			setOrganizations: (param) => {
				this.setOrganizations(param);
			},

			setActiveOrganization: (param) => {
				this.setActiveOrganization(param);
			},
			activeOrganization: false,

			getListClient: () => {
				this.getListClient();
			},
			Clients: false,

			setActiveClient: (param) => {
				this.setActiveClient(param);
			},
			activeClient: false,

			activeLanguage: 1,
			setActiveLanguage: (param) => {
				this.setActiveLanguage(param);
			},

			isFired: false,
		};
	}

	componentDidMount() {
		const { user } = this.state;

		if (user) {
			const us = JSON.parse(user);
			const groups = us.groups ? us.groups[0] : '';

			if (us.is_superuser) {
				this.getListClient();
			} else {
				if (groups && (groups.id === 7 || groups.name === 'Owner')) {
					const param = {
						client: us.client,
					};
					this.getListOrganization(param);
				} else if (groups && ([2, 9].includes(groups.id) || ['Manager', 'Organization Manager'].includes(groups.name))) {
					if (us.organizations && us.organizations.length > 0) {
						this.setState(
							{
								Organization: us.organizations,
							},
							() => {
								this.setState({
									activeOrganization: this.state.Organization[0].id,
								});
							}
						);
					}
				}
			}
		}

		const lang = localStorage.getItem('dig_a_l');
		this.setState({
			activeLanguage: lang ? lang : 1,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const { user, Clients, Organization, activeClient, activeOrganization } = this.state;

		if (user) {
			const us = JSON.parse(user);
			const groups = us.groups ? us.groups[0] : '';
			if (!Clients && !Organization && !activeClient && !activeOrganization) {
				if (us.is_superuser) {
					this.getListClient();
				} else {
					// if (groups && ([7, 9].includes(groups.id) || ["Owner", "Organization Manager"].includes(groups.name))) {
					// 	const param = {
					// 		client: us.client,
					// 	};
					// 	this.getListOrganization(param);
					// } else if (groups && (groups.id === 2 || groups.name === "Manager")) {
					// 	if (us.organizations && us.organizations.length > 0) {
					// 		this.setState(
					// 			{
					// 				Organization: us.organizations,
					// 			},
					// 			() => {
					// 				this.setState({
					// 					activeOrganization: this.state.Organization[0].id,
					// 				});
					// 			}
					// 		);
					// 	}
					// }
					if (groups && ([2, 7, 9].includes(groups.id) || ['Manager', 'Owner', 'Organization Manager'].includes(groups.name))) {
						if (us.organizations && us.organizations.length > 0) {
							this.setState(
								{
									Organization: us.organizations,
								},
								() => {
									this.setState({
										activeOrganization: this.state.Organization[0].id,
									});
								}
							);
						}
					} else {
						const param = {
							client: us.client,
						};
						this.getListOrganization(param);
					}
				}
			}
		}
	}

	getListClient = () => {
		const CL = new ClientApi();

		const param = {
			max_size: true,
		};
		CL.getListClients(param)
			.then((res) => {
				if (res.data) {
					this.setState(
						{
							Clients: res.data.results,
						},
						() => {
							const cl = localStorage.getItem('digibim-admin-client');

							this.setState(
								{
									activeClient: cl ? cl : this.state.Clients[0]?.id,
								},
								() => {
									const param = {
										client: this.state.activeClient,
									};
									this.getListOrganization(param);
								}
							);
						}
					);
				}
			})
			.catch((err) => {
				console.log(err);
				errorHandler(
					err.response?.data || 'Error',
					`${err.response?.status || '408'} ${err.response?.statusText || 'Request Timeout'} - Failed to get list of Clients at app.js`
				);
			});
	};

	setActiveClient = (param) => {
		this.setState(
			{
				activeClient: param,
			},
			() => {
				localStorage.removeItem('digibim-admin-org');
				localStorage.setItem('digibim-admin-client', param);
				const { activeClient } = this.state;
				if (activeClient) {
					const params = {
						client: this.state.activeClient ? parseInt(this.state.activeClient) : '',
					};
					this.getListOrganization(params);
				}
			}
		);
	};

	getListOrganization = (params) => {
		const LA = new OrganizationApi();

		LA.getListOrganization(params)
			.then((res) => {
				if (res.data) {
					this.setState(
						{
							Organization: res.data.results,
						},
						() => {
							const org = localStorage.getItem('digibim-admin-org');
							const { Organization } = this.state;

							this.setState(
								{
									activeOrganization: org ? org : Organization && Organization.length !== 0 ? Organization[0]?.id : false,
								},
								() => {
									// this.props.history.replace('/organizations');
								}
							);
						}
					);
				}
			})
			.catch((err) => {
				errorHandler(
					err.response?.data,
					`${err.response?.status} ${err.response?.statusText} - Failed to get list of Organization ${
						params ? JSON.stringify(params) : ''
					} at app.js`
				);
			});
	};

	setActiveOrganization = (param) => {
		this.setState(
			{
				activeOrganization: param,
			},
			() => {
				localStorage.setItem('digibim-admin-org', param);
			}
		);
	};

	setOrganizations = (param) => {
		this.setState({
			Organization: param,
		});
	};

	setDataUser = async (key, dataUser, client) => {
		setCookie(key, dataUser, client);

		const newArr = config;
		newArr.token = key;
		setArr(newArr);
		await this.setState(
			{
				client: JSON.stringify(client),
				userKey: key,
				user: JSON.stringify(dataUser),
				isSuperUser: dataUser ? dataUser.is_superuser : false,
				isGroups: dataUser ? dataUser.groups : false,
			},
			() => {
				return true;
			}
		);
		// this.props.history.replace("/")
	};

	removeDataUser = async () => {
		removeCookie();
		this.setState({
			client: false,
			userKey: false,
			user: false,
			isSuperUser: false,
			isGroups: false,

			Organization: false,
			activeOrganization: false,
			Clients: false,
			activeClient: false,
		});
		let newArr = {
			GA_ID: config.GA_ID,
			base_api: config.base_api,
			token: '',
			tokenDev: config.tokenDev,
		};
		const arr = await setArr(newArr);
		return arr ? true : false;
	};

	setActiveLanguage = (param) => {
		this.setState({
			activeLanguage: param,
		});
	};

	render() {
		const { userKey, user } = this.state;

		if (userKey && user) {
			let isObj = typeof user === 'object' && user !== null;
			return (
				<ErrorBoundary>
					<LoadScript googleMapsApiKey={this.state.gMapApiKey} libraries={this.state.mapsLib} loadingElement={<></>}>
						<Suspense>
							<>
								{/* {!Organization.length && <Backdrop />} */}
								<Context.Provider value={this.state}>
									<Router>
										<Site superuser={user ? (isObj ? user : JSON.parse(user)) : false} />
									</Router>
								</Context.Provider>
								<Toaster position='bottom-right' reverseOrder={true} />
							</>
						</Suspense>
					</LoadScript>
				</ErrorBoundary>
			);
		} else if (!userKey && !user) {
			return (
				<Context.Provider value={this.state}>
					<Suspense>
						<Router>
							<Switch>
								<Route path='/forgot-password' exact={true} render={(props) => <ForgotPassword {...props} />} />
								<Route
									path='/reset-password'
									exact={true}
									render={(props) => <ResetPassword {...props} setDataUser={this.setDataUser} />}
								/>
								<Route path='/register' exact={true} render={(props) => <Register {...props} setDataUser={this.setDataUser} />} />
								<Route path='/login' exact={true} render={(props) => <Login {...props} setDataUser={this.setDataUser} />} />
								<Route path='*' render={(props) => <Login {...props} setDataUser={this.setDataUser} />} />
							</Switch>
						</Router>
					</Suspense>
				</Context.Provider>
			);
		} else {
			return null
		}
	}
}

export default App;
