import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import Layout from '../../components/Layout/Layout'
import ControlPanels from '../../sections/ControlPanel/ControlPanel'

class ControlPanel extends Component {
    render() {
        const { t } = this.props;
		const transPage = 'sidebar.'
        return (
            <Layout title={t(transPage + 'control_panel')}>
                <ControlPanels />
            </Layout>
        )
    }
}
export default withTranslation()(ControlPanel);
