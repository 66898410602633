import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import Layout from '../../components/Layout/Layout'
import OwnerS from '../../sections/Owners/Owners'

class Owners extends Component {
    render() {
        const { t } = this.props;
		const transPage = 'sidebar.'
        return (
            <Layout title={t(transPage + 'owners')}>
                <OwnerS />
            </Layout>
        )
    }
}
export default withTranslation()(Owners);
