import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import Layout from '../../components/Layout/Layout'
import Project from '../../sections/Projects/Projects'
class Projects extends Component {
    render() {
        const { t } = this.props;
		const transPage = 'sidebar.'
        return (
            <Layout title={t(transPage + 'projects')}>
                <Project />
            </Layout>
        )
    }
}

export default withTranslation()(Projects);

