import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import InputMotion from '../../components/Input/InputMotion'
import Logo from '../../components/Logo/Logo'

import { checkInputValue } from "../../lib/Common";
import RegisterApi from '../../api/RegisterApi';

import classes from './ForgotPassword.module.css'
class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            canForgotPassword: false,
            isForgotPassword: false,
            errForgotPassowrd: false,

            email: true,
            transPage: "forgot_password.",
        }
    }

    inputOnChange = (e) => {
        let email = document.getElementById("email").value;

        const htmlId = e.target.id;
        const stateValue = checkInputValue(e.target.value);

        this.setState(
            {
                [htmlId]: stateValue,
            },
            () => {
                if (email && email.includes('@')) {
                    this.setState({
                        canForgotPassword: true,
                    });
                } else {
                    this.setState({
                        canForgotPassword: false,
                    });
                }
            }
        );
    }

    resetPassword = () => {
        const registerApi = new RegisterApi();

        const data = {
            email: this.state.email
        };

        registerApi
            .forgotPassword(data)
            .then((res) => {
                const dataReturn = res.data
                if (dataReturn) {
                    this.setState({
                        isForgotPassword: true
                    })
                }

            })
            .catch((err) => {
                console.log(err)
                if (err && err.response.data) {
                    let errText = err.response.data
					let txt = errText?.email[0]
                    this.setState({
                        email: false,
                        errForgotPassowrd: txt
                    })
                }
            });

    }

    render() {
        const { t } = this.props;
        const { transPage, errForgotPassowrd } = this.state

        return (
            <>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE} | Forgot Password</title>
                </Helmet>
                <Logo />
                <div className={[classes.reset, 'container'].join(' ')}>
                    <div className={classes.contentReset}>
                        {
                            !this.state.isForgotPassword ?
                                <>
                                    <h1>{t(transPage + "forgot_yout_password")}</h1>
                                    <p>{t(transPage + "email_caption")}</p>
                                    <InputMotion id='email' label={t('email')} type='email' important={true} desc={errForgotPassowrd ? errForgotPassowrd : false} onChange={(e) => this.inputOnChange(e)} status={this.state.email} />

                                    <button className={['btn-bim mt-5', classes.btnReset, (!this.state.canForgotPassword && 'bim-not-allowed')].join(' ')} onClick={this.resetPassword}>
                                        {t(transPage + 'reset_password')}
                                    </button>

                                    <span className='m-auto'><em>{t('or')}</em></span>
                                    <Link to='/login' className={['btn-bim', classes.btnSignIn].join(' ')}>
                                        {t('sign_in')}
                                    </Link>
                                </>
                                : <div className={classes.isForgotPassword}>
                                    {t(transPage + 'email_notification')}
                                    <Link to='/login' className='btn-bim small bim-pd-btn'>
                                        {t('oke')}
                                    </Link>
                                </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(ForgotPassword);

