import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import Layout from '../../components/Layout/Layout'
import TemplateConfigurator from '../../sections/Template/Template'
class Template extends Component {
    render() {
        const { t } = this.props;
		const transPage = 'sidebar.'
        return (
            <Layout title={t(transPage + 'template_configurator')}>
                <TemplateConfigurator />
            </Layout>
        )
    }
}
export default withTranslation()(Template);
