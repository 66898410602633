import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import Layout from '../../components/Layout/Layout'
import Manager from '../../sections/Managers/Managers'

class Managers extends Component {
    render() {
        const { t } = this.props;
		const transPage = 'sidebar.'
        return (
            <Layout title={t(transPage + 'managers')}>
                <Manager />
            </Layout>
        )
    }
}
export default withTranslation()(Managers);

