import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { withTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { IoSettings } from 'react-icons/io5';

import Context from '../../pages/Context';

import OrganizationApi from '../../api/OrganizationApi';
import LoginApi from '../../api/LoginApi';
import LanguageApi from '../../api/LanguageApi';
import ClientApi from '../../api/ClientApi';

import './Global.css';
import './Utilities.css';
import classes from './Header.module.css';
class Header extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			isSuperUser: false,

			Organization: [],
			Languages: [],
			Clients: [],
			activeClient: false,
			isData: false,

			transPage: 'sidebar.',
		};
	}

	componentDidMount() {
		const { client, user, isSuperUser, Organization, activeLanguage, Clients, activeClient } = this.context;

		if (client && user && Organization && Organization.length > 0) {
			this.setState(
				{
					isSuperUser: isSuperUser,
					Organization: Organization,
				},
				() => {
					if (Organization) {
						// setActiveOrganization(Organization[0].id)
					}
					if (isSuperUser) {
						if (!Clients.length) {
							this.getListClients();
						} else if (Clients.length) {
							this.setState({
								Clients: Clients,
								activeClient: activeClient,
							});
						}
					}
				}
			);
		}
		if (activeLanguage) {
			if (activeLanguage !== this.state.activeLanguage) {
				this.setState({
					activeLanguage: activeLanguage,
				});
			}
		}
		this.getLanguages();
	}

	componentDidUpdate() {
		const { client, user, Organization, isSuperUser, activeLanguage, Clients, activeClient } = this.context;
		if (client && user) {
			if (this.state.Organization !== Organization) {
				this.setState({
					Organization: Organization,
				});
			}
			if (this.state.isSuperUser !== isSuperUser) {
				this.setState(
					{
						isSuperUser: isSuperUser,
					},
					() => {
						if (isSuperUser) {
							if (!Clients.length) {
								this.getListClients();
							} else if (Clients.length) {
								this.setState({
									Clients: Clients,
									activeClient: activeClient,
								});
							}
						}
					}
				);
			}

			if (this.state.Clients && this.state.Clients.length > 0) {
				if (this.state.activeClient !== activeClient) {
					this.setState(
						{
							activeClient: activeClient,
						},
						() => {
							// const param = {
							// 	client: activeClient,
							// };
							// this.getListOrganization(param);
						}
					);
				}
			}
		}
		if (activeLanguage) {
			if (activeLanguage !== this.state.activeLanguage) {
				this.setState({
					activeLanguage: activeLanguage,
				});
			}
		}
	}

	getLanguages = () => {
		const LA = new LanguageApi();

		LA.getLanguages()
			.then((res) => {
				const dataReturn = res.data;
				if (dataReturn) {
					this.setState({
						Languages: dataReturn.results,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				if (err && err.response) {
					if (err.response.status === 401) {
						const contextValue = this.context;
						contextValue.removeDataUser();
						window.location = '/login';
					}
				}
			});
	};

	getListClients = () => {
		const CL = new ClientApi();

		CL.getListClients({ max_size: true })
			.then((res) => {
				const dataReturn = res.data;
				if (dataReturn) {
					this.setState(
						{
							Clients: dataReturn.results,
						},
						() => {
							const { Clients } = this.state;
							if (Clients && Clients.length > 0) {
								const param = {
									client: Clients[0].id,
								};
								this.getListOrganization(param);
							}
						}
					);
				}
			})
			.catch((err) => {
				console.log(err);
				if (err && err.response) {
					if (err.response.status === 401) {
						const contextValue = this.context;
						contextValue.removeDataUser();
						window.location = '/login';
					}
				}
			});
	};

	getListOrganization = (param) => {
		const OA = new OrganizationApi();

		this.setState({
			Organization: [],
		});
		OA.getListOrganization(param)
			.then((res) => {
				const dataReturn = res.data;
				if (dataReturn) {
					this.setState({
						Organization: dataReturn.results,
					});
				}
			})
			.catch((err) => {
				console.log(err);
				if (err && err.response) {
					if (err.response.status === 401) {
						const contextValue = this.context;
						contextValue.removeDataUser();
						window.location = '/login';
					}
				}
			});
	};

	goBack = () => {
		this.props.history.goBack();
	};

	logout = () => {
		const loginApi = new LoginApi();

		loginApi
			.signOut()
			.then((res) => {
				const data = res.data;
				if (data) {
					const contextValue = this.context;
					const isLogout = contextValue.removeDataUser();
					if (isLogout) {
						this.props.history.replace('/login');
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	onChangeOrganization = (e) => {
		const { Organization, setActiveOrganization } = this.context;

		if (e.target.value) {
			if (Organization) {
				setActiveOrganization(e.target.value);
			}
		}
	};

	onChangeLanguage = (val) => {
		const { setActiveLanguage } = this.context;
		const { Languages } = this.state;

		const id = Languages.filter((item) => parseInt(item.id) === parseInt(val));

		if (val && id) {
			setActiveLanguage(val);
			localStorage.setItem('dig_a_lg', id[0].abbreviation);
			localStorage.setItem('dig_a_l', val);
			window.location.reload();
		}
	};

	onChangeClients = (e) => {
		const { Clients, setActiveClient } = this.context;

		if (e.target.value) {
			if (Clients) {
				setActiveClient(e.target.value);
			}
		}
	};
	render() {
		const { isSuperUser, Organization, Languages, Clients, transPage } = this.state;
		const { client, user, activeLanguage, activeOrganization, activeClient } = this.context;
		const { t } = this.props;

		let cl, activeUser, fullName;
		let organizations, info, languages, clients, infoClient;
		let headerStyle;
		let activeSelection;

		if (client && user) {
			cl = JSON.parse(client);
			activeUser = JSON.parse(user);
			if (isSuperUser) {
				const currentPath = this.props.location.pathname;
				if (['project/', 'project/board/', 'project/edit', 'manager/edit'].find((u) => currentPath.includes(u))) {
					activeSelection = true;
				} else {
					activeSelection = false;
				}
			}

			if (Organization && Organization.length > 0) {
				const orgs = Organization.map((org) => {
					return (
						<option key={`${org.name}`} value={org.id}>
							{org.name}
						</option>
					);
				});

				organizations = (
					<select
						key='organization'
						className={classes.orglist}
						onChange={this.onChangeOrganization}
						defaultValue={activeOrganization}
						disabled={activeSelection}
					>
						{orgs}
					</select>
				);
			}

			if (Clients && Clients.length > 0) {
				const clnts = Clients.map((clt, i) => {
					return (
						<option key={`${clt.name}-${i}`} value={clt.id}>
							{clt.name}
						</option>
					);
				});

				clients = (
					<select
						key='clients'
						className={classes.orglist}
						onChange={this.onChangeClients}
						defaultValue={activeClient}
						disabled={activeSelection}
					>
						{clnts}
					</select>
				);
			}

			if (isSuperUser) {
				infoClient = clients;
				info = organizations;
				headerStyle = classes.headerSuper;
			} else {
				info = organizations ? organizations : <p className={classes.userNo}>{t(transPage + 'no_organization')}</p>;
				// info = <p className={classes.userNo}>Manager Organization</p>;
				headerStyle = '';
			}

			if (Languages && Languages.length > 0) {
				const langs = Languages.map((l) => {
					return (
						<button key={`${l.name}`} onClick={() => this.onChangeLanguage(l.id)} className={[classes.btnLang, +activeLanguage === +l.id && classes.btnLangActive].join(' ')}>
							{l.abbreviation}
						</button>
					);
				});

				languages = (
					<Dropdown className='dropdown-d'>
						<Dropdown.Toggle className='dropdown-d-toggle'>
							<IoSettings style={{ fontSize: 24 }} color='#ffffff' />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={this.logout}>{t('logoutC')}</Dropdown.Item>
							<Dropdown.Item>
								<div className={classes.btnContentLang}>
									<span>{t('project_details.language')}: </span>
									<div>{langs}</div>
								</div>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				);
			}

			const { first_name, last_name, email } = activeUser;
			if (first_name) {
				fullName = `${first_name} - ${last_name}`;
			} else {
				fullName = email;
			}
		}

		return (
			<>
				{cl && user && (
					<header className={[classes.header, headerStyle].join(' ')}>
						<div className={classes.contentHeader}>
							<div className={classes.userDetail}>
								<p className={classes.user}>{cl?.name}</p>
								{/* <p className={classes.userNo}>Org number : {cl.organization_number}</p> */}
								{isSuperUser ? (
									<div className={classes.userContent}>
										<div className='d-flex'>
											<label>{t(transPage + 'clients')}</label>
											{infoClient}
										</div>
										<div className='d-flex'>
											<label>{t(transPage + 'organization')}</label>
											{info}
										</div>
									</div>
								) : (
									info
								)}
							</div>
							<div className={[classes.linkTitle, !this.props.isBack ? 'ms-3' : ''].join(' ')}>
								{this.props.isBack && <BsChevronLeft className='me-3' onClick={this.goBack} />}
								{this.props.title}
							</div>
							<div className={classes.language}>
								<div className='d-flex'>
									<div className={[classes.headerTextContent, 'd-flex flex-column me-2'].join(' ')}>
										<span>{fullName}</span>
										<span>{activeUser.is_superuser ? 'Superuser' : activeUser.groups[0]?.name || 'User'}</span>
									</div>
									{languages}
								</div>
							</div>
						</div>
					</header>
				)}
			</>
		);
	}
}

// export default withRouter((props) => <Header {...props} />);
// export default withTranslation()(withRouter((props) => <Header {...props} />));
export default withRouter(withTranslation()((props) => <Header {...props} />));
