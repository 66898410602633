import React, { Component } from "react";
import { SketchPicker } from "react-color";
import Dropzone from "react-dropzone";
import { AiFillInfoCircle } from "react-icons/ai";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { withTranslation } from 'react-i18next';

import toast from "react-hot-toast";

import errorHandler from "../../lib/errorHandler";
import Context from "../../pages/Context";
import ModalRequest from "../../components/Modal/Request/Request";
import ModalCommon from "../../components/Modal/Common/Common";
import OrganizationApi from "../../api/OrganizationApi";

import classes from "./Template.module.css";
class Template extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			files: [],

			displayColorPickerMB: false,
			colorMB: "#1a4a97",
			displayColorPickerMC: false,
			colorMC: "#ffffff",
			displayColorPickerSB: false,
			colorSB: "#828282",
			displayColorPickerSC: false,
			colorSC: "#000000",
			displayColorPickerPB: false,
			colorPB: "#1a4a97",
			displayColorPickerPC: false,
			colorPC: "ffffff",

			isRequest: false,

			isData: false,
			activeOrganization: false,
			isDeleteLogo: false,
			dataOrganization: [],

			transPage: "template."
		};
	}

	componentDidMount() {
		const { Organization, activeOrganization } = this.context;
		if (Organization && activeOrganization) {
			this.setState(
				{
					activeOrganization: activeOrganization,
				},
				() => {
					this.getOrganizationDetail();
				}
			);
		}
	}

	componentDidUpdate() {
		const { Organization, activeOrganization } = this.context;
		if (Organization) {
			if (this.state.activeOrganization !== activeOrganization) {
				this.setState(
					{
						activeOrganization: activeOrganization,
					},
					() => {
						this.getOrganizationDetail();
					}
				);
			}
		}
	}

	getOrganizationDetail = () => {
		const OA = new OrganizationApi();
		const { activeOrganization } = this.state;
		if (activeOrganization) {
			OA.getOrganizationDetail(activeOrganization)
				.then((res) => {
					this.setState({
						colorMB: res.data.board_background_color ? res.data.board_background_color : "#1a4a97",
						colorMC: res.data.board_content_color ? res.data.board_content_color : "#ffffff",
						colorSB: res.data.site_background_color ? res.data.site_background_color : "#828282",
						colorSC: res.data.site_content_color ? res.data.site_content_color : "#000000",
						colorPB: res.data.public_background_color ? res.data.public_background_color : "#1a4a97",
						colorPC: res.data.public_content_color ? res.data.public_content_color : "#ffffff",
						preview: res.data.logo ? res.data.logo : false,
						dataOrganization: res.data,
						isData: true,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	handleClick = (param) => {
		switch (param) {
			case "mb":
				this.setState({ displayColorPickerMB: !this.state.displayColorPickerMB });
				break;
			case "mc":
				this.setState({ displayColorPickerMC: !this.state.displayColorPickerMC });
				break;
			case "sb":
				this.setState({ displayColorPickerSB: !this.state.displayColorPickerSB });
				break;
			case "sc":
				this.setState({ displayColorPickerSC: !this.state.displayColorPickerSC });
				break;
			case "pb":
				this.setState({ displayColorPickerPB: !this.state.displayColorPickerPB });
				break;
			case "pc":
				this.setState({ displayColorPickerPC: !this.state.displayColorPickerPC });
				break;
			default:
				break;
		}
	};

	handleClose = (param) => {
		switch (param) {
			case "mb":
				this.setState({ displayColorPickerMB: false });
				break;
			case "mc":
				this.setState({ displayColorPickerMC: false });
				break;
			case "sb":
				this.setState({ displayColorPickerSB: false });
				break;
			case "sc":
				this.setState({ displayColorPickerSC: false });
				break;
			case "pb":
				this.setState({ displayColorPickerPB: false });
				break;
			case "pc":
				this.setState({ displayColorPickerPC: false });
				break;
			default:
				break;
		}
	};

	handleChange = (color, param) => {
		switch (param) {
			case "mb":
				this.setState({
					colorMB: color.hex,
				});
				break;
			case "mc":
				this.setState({
					colorMC: color.hex,
				});
				break;
			case "sb":
				this.setState({
					colorSB: color.hex,
				});
				break;
			case "sc":
				this.setState({
					colorSC: color.hex,
				});
				break;
			case "pb":
				this.setState({
					colorPB: color.hex,
				});
				break;
			case "pc":
				this.setState({
					colorPC: color.hex,
				});
				break;
			default:
				break;
		}
		this.onChangeColor(color, param);
	};

	onChangeColor = (color, param) => {
		const OA = new OrganizationApi();
		const { activeOrganization } = this.state;

		let updated;
		switch (param) {
			case "mb":
				updated = "board_background_color";
				break;
			case "mc":
				updated = "board_content_color";
				break;
			case "sb":
				updated = "site_background_color";
				break;
			case "sc":
				updated = "site_content_color";
				break;
			case "pb":
				updated = "public_background_color";
				break;
			case "pc":
				updated = "public_content_color";
				break;
			default:
				break;
		}

		if (activeOrganization) {
			const data = {
				[updated]: color.hex,
			};
			OA.updateOrganization(activeOrganization, data)
				.then((res) => {
					if (res) {
						this.getOrganizationDetail();
					}
				})
				.catch((err) => {
					console.log(err);
					errorHandler(err.response.data, `${err.response.status} ${err.response.statusText} - Failed to add update template OG${activeOrganization}/P${updated}/CLR${color.hex}`)
				});
		}
	};

	onDrop = (files) => {
		this.setState(
			{
				files,
			},
			() => {
				files.forEach((file) => {
					this.setState({
						preview: URL.createObjectURL(Object.assign(file)),
					});
				});
				this.updateOrganizationLogo();
			}
		);
	};

	onDeleteLogo = () => {
		this.setState({
			files: null
		}, () => {
			this.updateOrganizationLogo()
		})
	}

	modalDeleteLogo = () => {
		this.setState({
			isDeleteLogo: !this.state.isDeleteLogo
		})
	}

	updateOrganizationLogo = () => {
		const OA = new OrganizationApi();
		const { activeOrganization, files } = this.state;

		if (activeOrganization) {
			const data = {
				logo: files ? files[0] : false,
			};
			OA.updateOrganizationLogo(activeOrganization, data)
				.then((res) => {
					if (res) {
						this.setState({
							isDeleteLogo: false
						})
						toast.success(`Successfully ${files ? 'update' : 'delete'} logo`, {
							duration: 3000,
							className: "toast-notification",
						});
						this.getOrganizationDetail();
					}
				})
				.catch((err) => {
					console.log(err);
					errorHandler(err.response.data, `${err.response.status} ${err.response.statusText} - Failed to add update organization Logo OG${activeOrganization}`)
				});
		}
	};

	modalRequest = () => {
		this.setState({
			isRequest: !this.state.isRequest,
		});
	};

	htmlSketchPicker = (param, defaultColor) => {
		let html = (
			<div className={classes.popover}>
				<div className={classes.cover} onClick={() => this.handleClose(param)} />
				<SketchPicker color={defaultColor} onChange={(color) => this.handleChange(color, param)} />
			</div>
		);
		return html;
	};

	requestConsultancy = () => { };

	renderSectionPicker = (title, data1, data2) => {
		let html =
			<div className={classes.colorPicker}>
				<span className={classes.templateTitle}>
					{title} &emsp;
					<AiFillInfoCircle color="#17438b" />
				</span>
				<div className="d-flex mt-5">
					<div className={classes.contentPicker}>
						{data1.title}
						<div className={classes.picker}>
							<div className={classes.swatch} onClick={() => this.handleClick(data1.param)}>
								<div className={classes.boxColor} style={data1.style} />
							</div>
							{data1.stateDisplay ? this.htmlSketchPicker(data1.param, data1.stateColor) : null}
						</div>
					</div>
					<div className={classes.contentPicker}>
						{data2.title}
						<div className={classes.picker}>
							<div className={classes.swatch} onClick={() => this.handleClick(data2.param)}>
								<div className={classes.boxColor} style={data2.style} />
							</div>
							{data2.stateDisplay ? this.htmlSketchPicker(data2.param, data2.stateColor) : null}
						</div>
					</div>
				</div>
			</div>

		return html
	}

	render() {
		const { Organization, activeOrganization } = this.context;
		const { isData, transPage } = this.state;
		const { t } = this.props;

		let thumbs, dropStyle;
		dropStyle = {
			marginBottom: "2rem",
		};
		if (this.state.preview) {
			thumbs = (
				<div className={classes.thumb} key={this.state.preview}>
					<div className={classes.thumbInner}>
						<img src={this.state.preview} className={classes.img} alt="" />
					</div>
				</div>
			);
			dropStyle = {
				marginBottom: "0rem",
			};
		}

		let data1a, data1b, data2a, data2b, data3a, data3b
		data1a = {
			title: "Background color",
			param: "sb",
			style: {
				background: this.state.colorSB,
			},
			stateDisplay: this.state.displayColorPickerSB,
			stateColor: this.state.colorSB,
		}
		data1b = {
			title: "Content color",
			param: "sc",
			style: {
				background: this.state.colorSC,
			},
			stateDisplay: this.state.displayColorPickerSC,
			stateColor: this.state.colorSC,
		}
		data2a = {
			title: "Background color",
			param: "mb",
			style: {
				background: this.state.colorMB,
			},
			stateDisplay: this.state.displayColorPickerMB,
			stateColor: this.state.colorMB,
		}
		data2b = {
			title: "Content color",
			param: "mc",
			style: {
				background: this.state.colorMC,
			},
			stateDisplay: this.state.displayColorPickerMC,
			stateColor: this.state.colorMC,
		}
		data3a = {
			title: "Background color",
			param: "pb",
			style: {
				background: this.state.colorPB,
			},
			stateDisplay: this.state.displayColorPickerPB,
			stateColor: this.state.colorPB,
		}
		data3b = {
			title: "Content color",
			param: "pc",
			style: {
				background: this.state.colorPC,
			},
			stateDisplay: this.state.displayColorPickerPC,
			stateColor: this.state.colorPC,
		}
		return (
			<>
				<div className="main-section">
					<div className="content-section">
						{isData && (
							<>
								<div className="w-100 h-auto mb-3">
									<span className={classes.templateTitle}>
										{t(transPage + 'company_logo')} &emsp;
										<AiFillInfoCircle color="#17438b" />
									</span>
									<Dropzone onDrop={this.onDrop} accept="image/*" maxFiles={1} multiple={false}>
										{({ getRootProps, getInputProps, open }) => (
											<>
												<section className="">
													<div style={dropStyle} {...getRootProps({ className: ["dropzone", classes.dropzone].join(" ") })}>
														<input {...getInputProps()} />
														{thumbs ? thumbs : <p>+ {t(transPage + "upload_logo")}</p>}
													</div>
												</section>
												{thumbs && (
													<div className={classes.thumbAction}>
														<FaPencilAlt color="#17438B" className="me-2" onClick={open} />
														<FaTrashAlt color="#17438B" onClick={this.modalDeleteLogo} />
													</div>
												)}
											</>
										)}
									</Dropzone>
								</div>

								{this.renderSectionPicker(t(transPage + 'menu_bar'), data2a, data2b)}

								{this.renderSectionPicker(t(transPage + 'site_information'), data1a, data1b)}

								{this.renderSectionPicker(t(transPage + 'public_documentation'), data3a, data3b)}
							</>
						)}

						{Organization && !Organization.length && !activeOrganization && (
							<div className="d-flex w-100 justify-content-center">
								<span>This Client doesn't have an organization</span>
							</div>
						)}
					</div>
				</div>

				<ModalRequest show={this.state.isRequest} onHide={this.modalRequest} title={"Request Consultancy"} action={this.requestConsultancy} />
				<ModalCommon show={this.state.isDeleteLogo} onHide={this.modalDeleteLogo} type="delete" action={this.onDeleteLogo} />
			</>
		);
	}
}
export default withTranslation()(Template);

