import axios from "axios";
import qs from "qs";
import config from "../lib/Config";
class ManagerApi {
	base_api = config.base_api;
	token = config.token;

	getListOrganization(params) {
		return axios.get(this.base_api + `organizations/`, {
			params: params,
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "comma" });
			},
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	getOrganizationDetail(organizationId) {
		return axios.get(this.base_api + `organizations/${organizationId}/`, {
			params: {},
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	addOrganization(data) {
		return axios.post(this.base_api + `organizations/`, data, {
			params: {},
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	updateOrganization(organizationId, data) {
		return axios.patch(this.base_api + `organizations/${organizationId}/`, data, {
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	updateOrganizationLogo(organizationId, data) {
		var bodyFormData = new FormData();
		if (data.logo) {
			bodyFormData.append(`logo`, data.logo);
		} else {
			bodyFormData.append(`logo`, "");
		}

		return axios.patch(this.base_api + `organizations/${organizationId}/`, bodyFormData, {
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "multipart/form-data",
			},
		});
	}

	deleteOrganization(organizationId) {
		return axios.delete(this.base_api + `organizations/${organizationId}/`, {
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}
}

export default ManagerApi;
