import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import App from './App';
import './i18n';
import './polyfills';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

if (process.env.REACT_APP_MODE === 'production') {
	ReactGA.initialize(process.env.REACT_APP_GA_ID);
}

ReactDOM.render(
	<React.StrictMode>
		<React.Suspense fallback={<></>}>
			<HelmetProvider>
				<App />
			</HelmetProvider>
		</React.Suspense>
	</React.StrictMode>,
	document.getElementById('root')
);
