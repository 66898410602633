import axios from "axios";
import config from "../lib/Config";
class OwnerApi {
	base_api = config.base_api;
	token = config.token;

	addOwner(data) {
		// enable if also want to create new owner along with new client
		// return axios.post(this.base_api + `users/register/`, data, {
		// 	params: {
		// 	},
		// 	headers: {
		// 		Authorization: "Token 0dc8907a481238f6501e9ff7144315877bb0ca82",
		// 		"Content-Type": "application/json",
		// 	},
		// })
		return axios.post(this.base_api + `owners/`, data, {
			params: {},
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	getListOwners(params) {
		return axios.get(this.base_api + `owners/`, {
			params: params,
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	getListClients(params) {
		return axios.get(this.base_api + `clients/`, {
			params: params,
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	getOwnerDetails(id) {
		return axios.get(this.base_api + `owners/${id}/`, {
			params: {},
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	updateOwner(ownerId, data) {
		var bodyFormData = new FormData();

		bodyFormData.append("client", data.client);
		bodyFormData.append("first_name", data.first_name);
		bodyFormData.append("last_name", data.last_name);
		bodyFormData.append("email", data.email);
		bodyFormData.append("phone", data.phone);
		
		if (data.password){
			if (typeof data.password !== "boolean" && data.password.trim().length > 3){
				bodyFormData.append("password", data.password);
			}
		}

		return axios.patch(this.base_api + `owners/${ownerId}/`, bodyFormData, {
			headers: {
				"Authorization": "Token " + this.token,
				"Content-Type": "multipart/form-data"
			}
		})
	}

	deleteOwner(ownerId) {
		return axios.delete(this.base_api + `owners/${ownerId}/`, {
			headers: {
				"Authorization": "Token " + this.token,
				"Content-Type": "application/json"
			}
		})
	}

}

export default OwnerApi;
