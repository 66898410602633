import React, { Component } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Pagination from "react-js-pagination";
import { withTranslation } from "react-i18next";
import { VscClose } from "react-icons/vsc";

import Context from "../../pages/Context";
import Loader from "../../components/Loader/Loader";
// import ModalDetailStatistic from "../../components/Modal/DetailStatistic/DetailStatistic";
import ProjectApi from "../../api/ProjectApi";
import OrganizationApi from "../../api/OrganizationApi";

import classes from "./Statistic.module.css";
class Statistic extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			data: [],

			dataStatistic: [],
			isData: false,

			activePage: 1,
			limitPageData: 10,
			currentPageData: 0,
			totalCount: 0,
			dataCount: 0,

			activeOrganization: false,

			organizationList: [],
			statusList: ["Not Activated", "Request Sent", "Active", "Cancelled", "Expired"],
			filterStatus: [],
			filterOrganization: [],

			transPage: "statistic.",
			transPageP: "project_list.",
			transPageT: "table."
		};
	}

	componentDidMount() {
		const { activeOrganization } = this.context;
		this.setState(
			{
				activeOrganization: activeOrganization,
			},
			() => {
				this.getOrganizations();
				this.handleProject();
			}
		);
	}

	componentDidUpdate() {
		const { user, Organization, activeOrganization } = this.context;
		if (user && Organization) {
			if (this.state.activeOrganization !== activeOrganization) {
				this.setState(
					{
						activeOrganization: activeOrganization,
					},
					() => {
						this.handleProject();
					}
				);
			}
		}
	}

	handleProject = () => {
		const { user, Organization, activeOrganization } = this.context;
		const { filterStatus, filterOrganization } = this.state;

		const { activePage } = this.state;

		if (user) {
			const us = JSON.parse(user);
			const groups = us.groups ? us.groups[0] : false;

			if (Organization && Organization.length > 0) {
				let param;
				if (us.is_superuser) {
					let param;
					if (filterStatus.length > 0 || filterOrganization.length > 0) {
						param = {
							organization__in: filterOrganization.map((org) => org.id),
							status__in: filterStatus,
							page: activePage ? activePage : 1,
						};
					} else {
						param = {
							organization: activeOrganization ? activeOrganization : false,
							page: activePage ? activePage : 1,
						};
					}
					this.getProjects(param);
				} else {
					if (activeOrganization) {
						if (groups && (groups.id === 7 || groups.name === "Owner")) {
							param = {
								client: us.client,
								organization: activeOrganization ? activeOrganization : "",
								page: activePage ? activePage : 1,
							};

						} else if (groups && (groups.id === 9 || groups.name === "Organization Manager")) {
							param = {
								client: us.client,
								organization: activeOrganization ? activeOrganization : "",
								page: activePage ? activePage : 1,
							};
						} else if (groups && (groups.id === 2 || groups.name === "Manager")) {
							param = {
								client: us.client,
								organization: activeOrganization,
								managers: us.id ? us.id : "",
								page: activePage ? activePage : 1,
							};
						}
						this.getProjects(param);
					}
				}
			} else {
				this.setState({
					isData: true,
					dataStatistic: [],
				});
			}
		}
	};

	getProjects(params) {
		const { user } = this.context;
		const PA = new ProjectApi();

		if (user) {
			PA.getProjects(params)
				.then((res) => {
					this.setState({ dataStatistic: res.data.results, isData: true, dataCount: res.data.count }, () => {
						this.setPagination();
					});
				})
				.catch((err) => console.log(err));
		}
	}

	setPagination = () => {
		const { dataStatistic } = this.state;
		let totalData = dataStatistic.length;
		let currentPageDatas;

		if (this.state.activePage === 1) {
			if (totalData >= this.state.limitPageData) {
				currentPageDatas = this.state.limitPageData;
			} else if (totalData < this.state.limitPageData) {
				currentPageDatas = totalData;
			}
		} else {
			currentPageDatas = (this.state.activePage - 1) * this.state.limitPageData + 1;
		}
		this.setState({
			currentPageData: currentPageDatas,
			totalCount: totalData,
		});
	};

	handlePageChange = (pageNumber) => {
		this.setState(
			{
				activePage: pageNumber,
			},
			() => {
				this.handleProject();
			}
		);
	};

	// modalDetailStatistic = (param) => {
	// 	if (param) {
	// 		this.setState({
	// 			activeProject: param,
	// 			isDetailStatistic: !this.state.isDetailStatistic,
	// 		});
	// 	} else {
	// 		this.setState({
	// 			activeProject: false,
	// 			isDetailStatistic: false,
	// 		});
	// 	}
	// };

	getOrganizations = () => {
		const OA = new OrganizationApi();
		const { user, Organization, activeOrganization } = this.context;
		const us = JSON.parse(user);
		const groups = us.groups ? us.groups[0] : false;

		if (us.is_superuser) {
			OA.getListOrganization({max_size:true})
			.then((res) => {
				this.setState({ organizationList: res.data.results, isOrganization: true }, () => { });
			})
			.catch((err) => console.log(err));
		} else {
			if (groups && Organization.length > 0 && activeOrganization) {
				this.setState({ organizationList: Organization, isOrganization: true }, () => { });
			} else {
				this.setState({ organizationList: [], isOrganization: true }, () => { });
			}
		}
	};

	onChangeFilter = (e, param) => {
		const val = e.target.value;

		if (val && val !== "x") {
			const data = this.checkingFilter(param);
			let arrData = data.arrData;
			let stateVal = data.stateVal;

			if (param === "status") {
				if (arrData.indexOf(val) === -1) {
					arrData.push(val);
				}
			} else {
				const nm = e.target.options[e.target.selectedIndex].text;
				const isExist = arrData.findIndex((p) => p.id === e.target.value);
				if (isExist === -1) {
					arrData.push({
						id: val,
						name: nm,
					});
				}
			}

			this.setState(
				{
					[stateVal]: arrData,
				},
				() => {
					this.handleProject();
				}
			);
		}
	};

	checkingFilter = (param) => {
		let arrData = [];
		let stateVal;
		const { filterStatus, filterOrganization } = this.state;

		switch (param) {
			case "status":
				arrData = [...filterStatus];
				stateVal = "filterStatus";
				break;
			case "organization":
				arrData = [...filterOrganization];
				stateVal = "filterOrganization";
				break;
			default:
				break;
		}

		return { arrData, stateVal };
	};

	removeFilter = (param, value) => {
		const data = this.checkingFilter(param);
		let arrData = data.arrData;
		let stateVal = data.stateVal;
		if (value) {
			let filtered;
			if (param === "status") {
				filtered = arrData.filter((val) => {
					return val !== value;
				});
			} else {
				filtered = arrData.filter((val) => {
					return val.id !== value;
				});
			}
			this.setState(
				{
					[stateVal]: filtered,
				},
				() => {
					this.handleProject();
				}
			);
		}
	};
	render() {
		const { isData, dataStatistic, transPage, transPageP, transPageT, statusList, filterStatus, organizationList, filterOrganization } = this.state;
		const { user } = this.context;
		const { t } = this.props;

		const columnsTable = [
			{
				name: t(transPageP + "project_name"),
				selector: (row) => row.name,
				sortable: true,
				cell: (row) => <Link to={`/statistics/detail/${row.id}`}>{row.name}</Link>,
			},
			{
				name: t(transPageP + "project_number"),
				selector: (row) => row.number,
				sortable: true,
			},
			{
				name: t(transPageP + "status"),
				selector: (row) => row.status,
				sortable: true,
				cell: (row) => {
					let styles;
					switch (row.status) {
						case "Expired":
							styles = classes.statusExpired;
							break;
						case "Not Activated":
							styles = classes.statusNotActivated;
							break;
						default:
							styles = classes.statusDefault;
							break;
					}
					return <div className={styles}>{row.status}</div>;
				},
			},
			{
				name: t(transPage + "page_view"),
				selector: (row) => row.login_times,
				sortable: true,
			},
		];

		let filterHtml;
		if (user) {
			const us = JSON.parse(user);
			if (us.is_superuser) {
				filterHtml = (
					<div className={classes.filterTable}>
						{dataStatistic && (
							<div className={classes.filterHtml}>
								<div className="d-flex align-items-center">
									<label htmlFor="statusFilter">{t(transPageP + "status")}</label>
									<select onChange={(e) => this.onChangeFilter(e, "status")}>
										<option value="x">---</option>
										{statusList.map((sl, i) => {
											return (
												<option key={`statusFilter-${sl}-${i}`} value={sl}>
													{sl}
												</option>
											);
										})}
									</select>
								</div>

								<div className={classes.filterSet}>
									{filterStatus &&
										filterStatus.length > 0 &&
										filterStatus.map((fs) => {
											return (
												<div className={classes.ftSelected} key={`statust${fs}`}>
													{fs} <VscClose onClick={() => this.removeFilter("status", fs)} />
												</div>
											);
										})}
								</div>
							</div>
						)}

						{dataStatistic && organizationList && organizationList.length > 0 && (
							<div className={classes.filterHtml}>
								<div className="d-flex align-items-center">
									<label htmlFor="organizationFilter">{t(transPageP + "organization")}</label>
									<select onChange={(e) => this.onChangeFilter(e, "organization")}>
										<option value="x">---</option>
										{organizationList.map((sl, i) => {
											return (
												<option key={`organizationFilter-${sl.name}-${i}`} value={sl.id}>
													{sl.name}
												</option>
											);
										})}
									</select>
								</div>

								<div className={classes.filterSet}>
									{filterOrganization &&
										filterOrganization.length > 0 &&
										filterOrganization.map((fo) => {
											return (
												<div className={classes.ftSelected} key={`organizationt${fo.id}`}>
													{fo.name} <VscClose onClick={() => this.removeFilter("organization", fo.id)} />
												</div>
											);
										})}
								</div>
							</div>
						)}
					</div>
				);
			}
		}
		return (
			<>
				<div className="main-section">
					<div className="content-section mt-0">
						{!isData ? (
							<div className="section-loader">
								<Loader loading={!isData} />
							</div>
						) : (
							<>
								{filterHtml}
								<DataTable columns={columnsTable} data={dataStatistic} className={["table table-bordered dataTable", classes.table].join(" ")} role="grid" defaultSortField="name" defaultSortAsc={false} selectableRowsHighlight={true} highlightOnHover={true} noDataComponent="This Organization doesn't have any project" />
								<div className={classes.pagination}>
									<Pagination activePage={this.state.activePage} itemsCountPerPage={this.state.limitPageData} totalItemsCount={this.state.dataCount} pageRangeDisplayed={4} onChange={this.handlePageChange.bind(this)} itemClass="page-item" linkClass="page-link"
										firstPageText={t(transPageT + "first")}
										prevPageText={t(transPageT + "prev")}
										nextPageText={t(transPageT + "next")}
										lastPageText={t(transPageT + "last")} />
									<div className="d-flex ml-3">
										<span className="mt-auto mr-0 mb-auto ml-auto" style={{ font: "12px Roboto", color: "#707070" }}>
											{t(transPageT + "showing")} {(this.state.activePage - 1) * this.state.limitPageData + 1} {t(transPageT + "of")} {this.state.currentPageData} {t(transPageT + "from")} {this.state.dataCount}
										</span>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</>
		);
	}
}
export default withTranslation()(Statistic);
