import React, { Component } from 'react'
import Swal from "sweetalert2";

import Context from '../../pages/Context'
import LanguageApi from '../../api/LanguageApi'
import classes from './Logo.module.css'

export default class Logo extends Component {
    static contextType = Context
    constructor(props) {
        super(props)
        this.state = {
            Languages: [],
            activeLanguage: 1
        }
    }

    componentDidMount() {
        this.getLanguages()
		const { activeLanguage } = this.context;
        if (activeLanguage) {
            this.setState({
                activeLanguage: activeLanguage
            })
        }
    }

    componentDidUpdate() {
        const {activeLanguage} = this.context;
        if (activeLanguage !== this.state.activeLanguage){
            this.setState({
                activeLanguage: activeLanguage
            })
        }
    }

    getLanguages = () => {
        const LA = new LanguageApi();

        LA.getLanguages()
            .then((res) => {
                const dataReturn = res.data;
                if (dataReturn) {
                    this.setState({
                        Languages: dataReturn.results,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                if (err && err.response) {
                    Swal.fire({
                        icon: "error",
                        title: `${err.response.status}`,
                        text: `${err.response.statusText}`,
                        confirmButtonColor: "#17438B",
                    });
                }
            });
    };
    onChangeLanguage = (e) => {
        const { Languages } = this.state;
        const val = e.target.value

        const id = Languages.filter((item) => parseInt(item.id) === parseInt(val));

        if (val && id) {
            localStorage.setItem("dig_a_lg", id[0].abbreviation);
            localStorage.setItem("dig_a_l", e.target.value);
            window.location.reload();
        }
    };
    render() {
        const { Languages, activeLanguage } = this.state

        let languages
        if (Languages && Languages.length !== 0) {
            const langs = Languages.map((l) => {
                return (
                    <option key={`${l.name}`} value={l.id}>
                        {l.name}
                    </option>
                );
            });
            languages = (
                <select key="admin-language" className={[classes.orglist, "w-100"].join(" ")} onChange={this.onChangeLanguage} defaultValue={activeLanguage ? activeLanguage : 1}>
                    {langs}
                </select>
            );
        }
        return (
            <div>
                <div className={classes.logo}>
                    <span>DIGIBIM</span>
                </div>
                <div className={classes.lang}>
                    {languages}
                </div>
            </div>
        )
    }
}
