import React, { Component, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Organizations from '../Organizations/Organizations';
import ControlPanel from '../ControlPanel/ControlPanel.js';

import Projects from '../Projects/Projects';

import Managers from '../Managers/Managers';
import OrgManagers from '../OrgManagers/OrgManagers';

import Statistics from '../Statistic/Statistic';
import Assets from '../Assets/Assets';
import Template from '../Template/Template';
import Owners from '../Owners/Owners';

import NotFound from '../../sections/Error/Error';
import Context from '../Context';

const ProjectDetail = lazy(() => import('../ProjectDetail/ProjectsDetail'));
const EditProject = lazy(() => import('../EditProject/EditProject'));
const EditBoard = lazy(() => import('../EditBoard/EditBoard'));
const NewProject = lazy(() => import('../NewProject/NewProject'));

const NewManager = lazy(() => import('../NewManager/NewManager'));
const EditManager = lazy(() => import('../EditManager/EditManager'));
const NewOrgManager = lazy(() => import('../NewOrgManager/NewOrgManager'));
const EditOrgManager = lazy(() => import('../EditOrgManager/EditOrgManager'));

const StatisticsDetail = lazy(() => import('../StatisticDetail/StatisticDetail'));

const NewOwner = lazy(() => import('../NewOwner/NewOwner'));
const EditOwner = lazy(() => import('../EditOwner/EditOwner'));

const EmailTemplate = lazy(() => import('../EmailTemplate/EmailTemplate'));

const Reports = lazy(() => import('../Reports/Reports'));

class site extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { superuser } = this.props;
		const { Organization } = this.context;

		const routeRedirect =
			!superuser?.is_superuser &&
			!Organization?.length &&
			([7, 9].includes(superuser?.groups[0]?.id) || ['Owner', 'Organization Manager'].includes(superuser?.groups[0]?.name));
		const routeDisable = superuser?.is_superuser ? false : routeRedirect;
		return (
			<Switch>
				<Route path='/error/:id' component={NotFound} />

				{superuser?.is_superuser && <Route path='/reports' exact component={Reports} />}

				{superuser?.is_superuser && <Route path='/owner/edit/:id' exact component={EditOwner} />}
				{superuser?.is_superuser && <Route path='/owners' exact component={Owners} />}
				{superuser?.is_superuser && <Route path='/owners/new' exact component={NewOwner} />}
				{superuser?.is_superuser && <Route path='/email-template' exact component={EmailTemplate} />}
				{(superuser?.is_superuser || superuser?.groups[0]?.id === 7) && <Route path='/template-configurator' exact component={Template} />}

				<Route path='/statistics/detail/:id' component={StatisticsDetail}>
					{routeDisable && <Redirect to='/organizations' />}
				</Route>
				<Route path='/statistics' exact component={Statistics}>
					{routeDisable && <Redirect to='/organizations' />}
				</Route>

				{superuser?.is_superuser && <Route path='/assets' exact component={Assets} />}

				{(superuser?.is_superuser || !superuser?.is_superuser) && (
					<Route path='/organization-manager/edit/:id' exact component={EditOrgManager}>
						{routeDisable && <Redirect to='/organizations' />}
					</Route>
				)}
				{(superuser?.is_superuser || [7, 9].includes(superuser?.groups[0]?.id)) && (
					<Route path='/organization-manager/new' exact component={NewOrgManager}>
						{routeDisable && <Redirect to='/organizations' />}
					</Route>
				)}
				{(superuser?.is_superuser || [7, 9].includes(superuser?.groups[0]?.id)) && (
					<Route path='/organization-managers' exact component={OrgManagers}>
						{routeDisable && <Redirect to='/organizations' />}
					</Route>
				)}

				{(superuser?.is_superuser || !superuser?.is_superuser) && (
					<Route path='/manager/edit/:id' exact component={EditManager}>
						{routeDisable && <Redirect to='/organizations' />}
					</Route>
				)}
				{(superuser?.is_superuser || [7, 9].includes(superuser?.groups[0]?.id)) && (
					<Route path='/manager/new' exact component={NewManager}>
						{routeDisable && <Redirect to='/organizations' />}
					</Route>
				)}
				{(superuser?.is_superuser || [7, 9].includes(superuser?.groups[0]?.id)) && (
					<Route path='/managers' exact component={Managers}>
						{routeDisable && <Redirect to='/organizations' />}
					</Route>
				)}

				<Route path='/project/board/:id' component={EditBoard}>
					{routeDisable && <Redirect to='/organizations' />}
				</Route>

				<Route path='/project/edit/:id' component={EditProject}>
					{routeDisable && <Redirect to='/organizations' />}
				</Route>

				<Route path='/project/new' component={NewProject}>
					{routeDisable && <Redirect to='/organizations' />}
				</Route>
				<Route path='/project/:id' component={ProjectDetail}>
					{routeDisable && <Redirect to='/organizations' />}
				</Route>

				<Route path='/projects' exact component={Projects}>
					{routeDisable && <Redirect to='/organizations' />}
				</Route>

				{/* super admin is OWNER */}
				{(superuser?.is_superuser || superuser?.groups[0]?.id === 7) && <Route path='/organizations' component={Organizations} />}

				{/* superuser handle Digibim Control Panel */}
				{superuser?.is_superuser && <Route path='/control-panel' component={ControlPanel} />}
				{!superuser?.is_superuser ? <Route path='/' exact component={Projects} /> : <Route path='/' exact component={ControlPanel} />}
				<Route path='/login' exact>
					<Redirect to='/'></Redirect>
				</Route>
				<Route component={NotFound} />
			</Switch>
		);
	}
}

export default site;
