import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import Layout from '../../components/Layout/Layout'
import Statistics from '../../sections/Statistic/Statistic'

class Statistic extends Component {
    render() {
        const { t } = this.props;
		const transPage = 'sidebar.'
        return (
            <Layout title={t(transPage + 'statistics')}>
                <Statistics />
            </Layout>
        )
    }
}
export default withTranslation()(Statistic);

