import axios from "axios";
import config from "../lib/Config";

class LanguageApi {
	base_api = config.base_api;
	token = config.token;
	tokenDev = config.tokenDev;

	getLanguages() {
		return axios.get(this.base_api + `languages/`, {
			params: {},
			headers: {
				// Authorization: `Token ${this.token ? this.token : this.tokenDev}`,
				"Content-Type": "application/json",
			},
		});
	}
}

export default LanguageApi;
