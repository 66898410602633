import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withTranslation } from 'react-i18next';

import Context from "../../../pages/Context";
import ActivationApi from "../../../api/ActivationApi";

import classes from "./ActivateProjectCP.module.css";
// import { actions } from "react-table";

class ActivateProjectCP extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			Clients: [],
			Organization: [],
			dataProject: [],
			projectStatus: false,
			isData: false,

			transPage: 'activate_project_form.'
		};
	}

	onOpenModal = () => {
		const project = this.props.project;
		const AA = new ActivationApi()

		if (project) {
			const param = {
				project: project.id,
				ordering: '-date_from'
			}
			AA.getProjectActivation(param)
				.then(res => {
					if (res.data) {
						this.setState({
							dataProject: res.data?.results[0],
							isData: true,
							projectStatus: project.status
						})
					}
				})
				.catch((err) => console.log(err));
		}
		// console.log("project", this.props.activateProject)
	};

	action = (param) => {
		if (param) {
			if (param.toLowerCase() === "deactivate") {
				this.props.action("Cancelled")
			} else {
				const p = param.charAt(0).toUpperCase() + param.slice(1).toLowerCase()
				this.props.action(p)
			}
		}
	};

	render() {
		const { isData, dataProject, projectStatus } = this.state
		const { t } = this.props;
		const { transPage } = this.state

		let actionString
		if (isData && projectStatus) {
			if (projectStatus === "Not Activated") {
				actionString = "Active"
			} else if (projectStatus === "Request Sent") {
				actionString = "Active"
			} else if (projectStatus.includes("Active until")) {
				actionString = "Deactivate"
			} else if (projectStatus === "Expired") {
				actionString = "Active"
			} else if (projectStatus === "Cancelled") {
				actionString = "Active"
			} else {
				actionString = "Active"
			}
		}

		return (
			<>
				<Modal show={this.props.show} onHide={this.props.onHide} size="lg" onShow={this.onOpenModal} className="modal-bim">
					{isData && dataProject && (
						<>
							<Modal.Header closeButton className="modal-bim-header">
								<Modal.Title>{t(transPage + 'activate_project')}</Modal.Title>
							</Modal.Header>
							<Modal.Body className="modal-bim-body">
								<div className="modal-bim-content">
									<p className={classes.subTitle}>{t(transPage + 'invoice_information')}</p>
									<div className="d-flex mb-3">
										<div className={["col-md-5 p-0", classes.modalLabel].join(" ")}>
											<p>{t(transPage + 'company_name')}</p>
											<p>{t(transPage + 'company_org')}</p>
											<p>{t(transPage + 'project_name')}</p>
											<p>{t(transPage + 'ordered_by')}</p>
											<p>{t(transPage + 'reference')}</p>
										</div>
										<div className={["col-md-7 p-0", classes.modalContent].join(" ")}>
											<p>{dataProject.project.organization?.name}</p>
											<p>{dataProject.project.organization?.number}</p>
											<p>{dataProject.project.name}</p>
											<p>{dataProject.project.owner?.email}</p>
											<p>{dataProject.reference}</p>
										</div>
									</div>
									<p className={classes.subTitle}>{t(transPage + "agreement")}</p>
									<div className="d-flex">
										<div className={["d-flex flex-column col-md-5 p-0", classes.modalLabel].join(" ")}>
											<p className="mt-2 mb-2">{t(transPage + "start_date")}</p>
											<input type="date" id="date-from" name="date-from" className=" form-control mb-3 w-75" defaultValue={dataProject.date_from} disabled />

											<p className="mb-2">{t(transPage + "price_per_month")}</p>
											<input type="text" id="price-per-month" name="price-per-month" className=" form-control w-75" defaultValue={`kr ${dataProject.project.template?.rate},-`} disabled />
										</div>
										<div className={["d-flex flex-column col-md-7 p-0", classes.modalContent].join(" ")}>
											<p className="mt-2 mb-2">{t(transPage + "number_of_months")}</p>
											<input type="number" id="number-month" name="number-month" min="1" className="form-control mb-3 w-75" defaultValue={dataProject.number_of_month} disabled />

											<p className="mb-2">{t(transPage + "total_price")}</p>
											<input type="text" id="total-price" name="total-price" min="1" className="form-control w-75" defaultValue={`kr ${dataProject.project.template?.rate * dataProject.number_of_month},-`} disabled />
										</div>
									</div>
								</div>
							</Modal.Body>
							<Modal.Footer className="modal-bim-footer">
								<div className="w-100 d-flex justify-content-between">
									<button className="btn-bim btn-bim-white small bim-pd-btn" onClick={this.props.onHide}>
										{t('cancel')}
									</button>
									<button className="btn-bim small bim-pd-btn uppercase" onClick={() => this.action(actionString)}>
										{actionString}
									</button>
								</div>
							</Modal.Footer>
						</>
					)}
				</Modal>
			</>
		);
	}
}
export default withTranslation()(ActivateProjectCP)
