import React, { Component } from 'react'
import { Modal } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class DeleteOwner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isChecked: false,

            transPage: "new_owner."
        }
    }

    onOpenModal = () => {

    }

    action = () => {
        this.props.action()
    }

    onChangeValue = () => {
        this.setState({
            isChecked: !this.state.isChecked
        })
    }

    render() {
        const { t } = this.props
        const {transPage} = this.state
        const owner = this.props.data

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="md"
                onShow={this.onOpenModal}
                className='modal-bim'
            >
                <Modal.Header closeButton className="modal-bim-header">
                    <Modal.Title> {t(transPage + "delete_owner")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-bim-body'>
                    <div className='modal-bim-content'>
                        <p>
                            <strong>{`${owner.email}`}</strong> currently {owner.organizations ? `has assign into ${owner.organizations.length} active organizations.` : `doesn't have any organization.`}
                            In case of deleting the owner, make sure at least assign an owner to a client so that you can still manage all organizations, managers and projects.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='modal-bim-footer'>
                    <div className='w-100 d-flex justify-content-between'>
                        <button className='btn-bim btn-bim-white small bim-pd-btn uppercase' onClick={this.props.onHide}>
                            {t("cancel")}
                        </button>
                        <button className='btn-bim small bim-pd-btn uppercase' onClick={this.action}>
                            {t("delete")}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default withTranslation()(DeleteOwner);

