import Swal from "sweetalert2";
import toast from "react-hot-toast";

const defineValue = (param) => {
    let labelText, notifText
    switch (param) {
        case 'wait':
            labelText = 'Waiting. . .'
            notifText = ''
            break;
        case 'save':
            labelText = 'Saving. . .'
            notifText = 'Successfully saved'
            break;
        case 'edit':
            labelText = 'Editing. . .'
            notifText = 'Successfully updated'
            break;
        case 'delete':
            labelText = 'Deleting. . .'
            notifText = 'Successfully deleted'
            break;
        case 'send':
            labelText = 'Sending. . .'
            notifText = 'Success, Please check your email'
            break;
        case 'reset':
            labelText = 'Reseting. . .'
            notifText = 'Success, Please try login again'
            break;
        case 'create':
            labelText = 'Creating. . .'
            notifText = 'Successfully created'
            break;
        default:
            break;
    }
    return { labelText, notifText }
}

const notification = () => {
    let toastId
    const notify = (param, text) => {
        let label = !text ? defineValue(param) : text
        toast.success(label.notifText, {
            id: toastId,
            duration: 2000,
            className: "toast-notification",
        });
    }

    const loadingfy = (param) => {
        let label = defineValue(param)
        toastId = toast.loading(label.labelText);
    }

    const notifWithPromise = (actionPromise) => {
        const action = actionPromise
        toast.promise(
            action,
            {
                loading: 'Loading',
                success: (data) => `Successfully saved`,
                error: (err) => `Failed to save`,
            },
            {
                style: {
                    minWidth: '250px',
                },
                success: {
                    duration: 3000,
                },
            }
        );
    }

    const notifWithAlert = (err, errText) => {
        toast.remove(toastId);
        Swal.fire({
            icon: "error",
            title: `${err.status} - ${err.statusText}`,
            text: errText,
            confirmButtonColor: "#17438B",
        });
    }

    return {
        loadingfy: loadingfy,
        notify: notify,
        notifPromise: notifWithPromise,
        notifAlertfy: notifWithAlert
    }
}

export default notification