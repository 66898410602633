import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { BsCheck } from "react-icons/bs";
import { withTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet-async";

import { checkInputValue } from "../../lib/Common";

import InputMotion from "../../components/Input/InputMotion";
import Logo from "../../components/Logo/Logo";

import RegisterApi from "../../api/RegisterApi";

import classes from "./ResetPassword.module.css";

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: true,
			canReset: false,
			isResetPassword: false,

			newPassword: true,
			rePassword: true,

			transPage: "reset_password.",
			transPageErr: "error."
		};
	}

	componentDidMount() {
		const search = this.props.location.search;
		const token = new URLSearchParams(search).get("token");
		if (token) {
			this.setState({
				token: token,
			});
		} else {
			this.props.history.replace("/login")
		}
	}

	inputOnChange = (e, validation) => {
		let newPassword = document.getElementById("newPassword").value;
		let rePassword = document.getElementById("rePassword").value;

		const htmlId = e.target.id;
		const stateValue = checkInputValue(e.target.value);

		if (validation) {
			if (e.target.value.length >= 8) {
				this.setState({
					[htmlId]: stateValue,
				});
			} else if (e.target.value.length < 8) {
				this.setState({
					[htmlId]: false,
				});
			}
		} else {
			this.setState({
				[htmlId]: stateValue,
			});
		}

		if (this.state.token && newPassword && rePassword && newPassword === rePassword) {
			this.setState({
				canReset: true,
			});
		} else {
			this.setState({
				canReset: false,
			});
		}
	};

	resetPassword = () => {
		const registerApi = new RegisterApi();
		const {transPageErr} = this.state
		const {t} = this.props

		if (this.state.token) {
			const data = {
				token: this.state.token,
				password: this.state.rePassword,
			};

			registerApi
				.resetPassword(data)
				.then((res) => {
					const dataReturn = res.data;
					if (dataReturn) {
						this.setState({
							isResetPassword: true,
						});
					}
				})
				.catch((err) => {
					console.log(err);
					if (err && err.response.data) {
						// let errText = err.response.data;
						// let txt = errText?.detail;

						let timerInterval;
						Swal.fire({
							title: t(transPageErr + "went_wrong"),
							icon: "error",
							html: `<p>${t(transPageErr + "went_wrong")} </p><br/>` +
								"Redirect in <b></b> seconds",
							timer: 5000,
							timerProgressBar: true,
							didOpen: () => {
								Swal.showLoading();
								timerInterval = setInterval(() => {
									Swal.getHtmlContainer().querySelector("b").textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
								window.location = "/login";
							},
						}).then((result) => {
							if (result.dismiss === Swal.DismissReason.timer) {
								console.log("I was closed by the timer");
							}
						});
					}
				});
		}
	};

	render() {
		const { t } = this.props;
		const { transPage } = this.state;

		return (
			<>
				<Helmet>
					<title>{process.env.REACT_APP_TITLE} | Reset Password</title>
				</Helmet>
				<Logo />
				<div className={[classes.reset, "container"].join(" ")}>
					<div className={classes.contentReset}>
						{!this.state.isResetPassword ? (
							<>
								<h1>{t(transPage + "reset_password")}</h1>
								<div className={classes.content}>
									<InputMotion id="newPassword" label={t(transPage + "password_caption")} type="password" important={true} desc={false} onChange={(e) => this.inputOnChange(e, true)} status={this.state.newPassword} />
									<InputMotion id="rePassword" label={t(transPage + "confirm_password")} type="password" important={true} desc={false} onChange={(e) => this.inputOnChange(e, true)} status={this.state.rePassword} />
								</div>
								<button className={["btn-bim mt-3", classes.btnReset, !this.state.canReset && "bim-not-allowed"].join(" ")} onClick={this.resetPassword}>
									{t(transPage + "reset_passwordC")}
								</button>

								<span className="m-auto">
									<em>{t("or")}</em>
								</span>
								<Link to="/login" className={["btn-bim", classes.btnSignIn].join(" ")}>
									{t("sign_inC")}
								</Link>
							</>
						) : (
							<div className={classes.isResetPassword}>
								<BsCheck />
								{t(transPage + "reset_notification")}
								<Link to="/login" className="btn-bim">
									{t("ok")}
								</Link>
							</div>
						)}
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(withTranslation()(ResetPassword));
