import axios from "axios";
import config from "../lib/Config";
class RegisterApi {
  base_api = config.base_api;
  token = config.token;

  registerUser(data) {
    return axios.post(this.base_api + `users/register/`, data, {
      params: {
      },
      headers: {
				Authorization: "Token 0dc8907a481238f6501e9ff7144315877bb0ca82",
				"Content-Type": "application/json",
			},
    })
  }
  
  forgotPassword(data) {
    return axios.post(this.base_api + `password_reset/`, data, {
      params: {

      }
    })
  }

  resetPassword(data) {
    return axios.post(this.base_api + `password_reset/confirm/`, data, {
      params: {

      }
    })
  }
  

}

export default RegisterApi;
