import React, { Component } from "react";

import { Modal } from "react-bootstrap";

export default class Organization extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: "Add",
			placeholder: "Organization Name",
			defaultContent: "",
		};
	}

	onOpenModal = () => {};

	onHideModal = () => {};

	action = () => {
		const param = {
			name: document.getElementById("organization-name").value,
			number: document.getElementById("organization-number").value,
		}
		this.props.action(param);
	};

	render() {
		const type = this.props.type;
		const organization = this.props.organization;
		let placeholder, defaultContent, placeholder1, defaultContent1;
		if (type === "Add") {
			placeholder = "Organization Name";
			placeholder1 = "Organization Number";
		} else if (type === "Update") {
			if (organization) {
				placeholder = defaultContent = organization.name;
				placeholder1 = defaultContent1 = organization.number;
			}
		}
		return (
			<Modal show={this.props.show} onHide={this.props.onHide} size="md" onShow={this.onOpenModal} className="modal-bim">
				<Modal.Header closeButton className="modal-bim-header">
					<Modal.Title> {type} Organization </Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-bim-body">
					<div className="modal-bim-content d-flex flex-column">
						<div className="d-flex align-items-center mb-3">
							<label htmlFor="organization-name" className="w-50">Organization Name</label>
							<input type="text" id="organization-name" placeholder={placeholder} className="form-control modal-bim-input w-50 ms-3" defaultValue={defaultContent} />
						</div>
						<div className="d-flex align-items-center mb-3">
							<label htmlFor="organization-number" className="w-50">Organization Number</label>
							<input type="text" id="organization-number" placeholder={placeholder1} className="form-control modal-bim-input w-50 ms-3" defaultValue={defaultContent1} />
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="modal-bim-footer">
					<div className="w-100 d-flex justify-content-between">
						<button className="btn-bim btn-bim-white small" onClick={this.props.onHide}>
							CANCEL
						</button>
						<button className="btn-bim small" onClick={this.action}>
							{type === "Add" ? "ADD" : "UPDATE"}
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		);
	}
}
