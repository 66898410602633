import React, { Component } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Pagination from "react-js-pagination";
import { withTranslation } from 'react-i18next';
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";

import ModalDeleteManager from "../../components/Modal/DeleteOrgManager/DeleteOrgManager";
import Loader from "../../components/Loader/Loader";
import Context from "../../pages/Context";

import OrgManagerApi from "../../api/OrgManagerApi";

import classes from "./OrgManagers.module.css";
class OrgManagers extends Component {
	static contextType = Context;
	constructor(props) {
		super(props);
		this.state = {
			data: [],

			dataManager: [],
			isData: false,

			activePage: 1,
			limitPageData: 10,
			currentPageData: 0,
			totalCount: 0,
			dataCount: 0,

			activeManager: false,
			isDeleteManager: false,

			activeOrganization: false,
			transPage: 'managers_list.',
			transPageT: "table."
		};
	}

	componentDidMount() {
		const { user, activeOrganization } = this.context;

		if (user) {
			if (activeOrganization) {
				this.getListOrgManagers(activeOrganization);
			} else {
				this.setState({
					dataManager: [],
					isData: true,
				})
			}
		}
	}

	componentDidUpdate() {
		const { Organization, activeOrganization } = this.context;
		if (Organization) {
			if (this.state.activeOrganization !== activeOrganization) {
				this.setState(
					{
						activeOrganization: activeOrganization,
					},
					() => {
						this.getListOrgManagers(activeOrganization);
					}
				);
			}
		}
	}

	getListOrgManagers = (param) => {
		const { user, activeClient } = this.context;
		const { activePage } = this.state
		const managerApi = new OrgManagerApi();

		if (user) {
			let objUser = JSON.parse(user);
			let idCl = objUser.client;

			let params;
			if (param) {
				params = {
					client: objUser.is_superuser ? activeClient : idCl,
					organizations: param,
					page: activePage ? activePage : 1
				};
			} else {
				params = {
					client: objUser.is_superuser ? activeClient : idCl,
					page: activePage ? activePage : 1
				};
			}

			managerApi
				.getListOrgManagers(params)
				.then((res) => {
					this.setState(
						{
							dataManager: res.data.results,
							isData: true,
							dataCount: res.data.count
						},
						() => {
							this.setPagination();
						}
					);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	setPagination = () => {
		const { dataManager } = this.state;
		let totalData = dataManager.length;
		let currentPageData;

		if (this.state.activePage === 1) {
			if (totalData >= this.state.limitPageData) {
				currentPageData = this.state.limitPageData;
			} else if (totalData < this.state.limitPageData) {
				currentPageData = totalData;
			}
		} else {
			// currentPageData = totalData - (this.state.activePage - 1) * this.state.limitPageData;
			currentPageData = (this.state.activePage - 1) * this.state.limitPageData + 1;
		}
		this.setState({
			currentPageData: currentPageData,
			totalCount: totalData,
		});
	};

	handlePageChange = (pageNumber) => {
		const { activeOrganization } = this.state
		this.setState({
			activePage: pageNumber,
		}, () => {
			if (activeOrganization) {
				this.getListOrgManagers(activeOrganization);
			}
		});
	};

	modalDeleteManager = (param) => {
		if (param) {
			this.setState({
				activeManager: param,
				isDeleteManager: !this.state.isDeleteManager,
			});
		} else {
			this.setState({
				activeManager: false,
				isDeleteManager: false,
			});
		}
	};

	deleteOrgManager = () => {
		const { activeOrganization } = this.context;
		if (this.state.dataManager && this.state.activeManager) {
			const managerApi = new OrgManagerApi();

			managerApi
				.deleteOrgManager(this.state.activeManager.id)
				.then((res) => {
					if (res) {
						this.setState(
							{
								activeManager: false,
								isDeleteManager: false,
							},
							() => {
								toast.success("Delete successfully", {
									duration: 1000,
									className: "toast-notification",
								});
								if (activeOrganization) {
									this.getListOrgManagers(activeOrganization);
								}
							}
						);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	render() {
		const { t } = this.props;
		const { transPage, transPageT } = this.state
		const { user } = this.context;

		let cl, groups
		if (user) {
			cl = JSON.parse(user);
			groups = cl.groups ? cl.groups[0] : false;

		}
		const columnsTable = [
			{
				name: t(transPage + 'manager'),
				selector: (row) => row.first_name,
				sortable: true,
				cell: (row) => (
					<Link to={`/organization-manager/edit/${row["id"]}`}>
						<span>{`${row["first_name"]} ${row["last_name"]}`}</span>
					</Link>
				),
			},
			{
				name: t(transPage + 'email'),
				selector: (row) => row.email,
				sortable: true,
				cell: (row) => row["email"],
			},
			{
				name: t(transPage + 'phone_number'),
				selector: (row) => row.phone,
				sortable: true,
				cell: (row) => <span>{row["phone"]}</span>,
			},
			{
				name: t(transPage + 'active_project'),
				selector: (row) => row.projects,
				sortable: true,
				cell: (row) => <span>{row["projects"]}</span>,
			},
			{
				name: "",
				selector: (row) => row.id,
				sortable: false,
				cell: (row) => (cl.is_superuser || (groups && (groups.id === 7 || groups.name === "Owner"))) && <MdDelete className={classes.icon} key={row["id"]} onClick={() => this.modalDeleteManager(row)} />,
			},
		];

		return (
			<>
				<div className="main-section">
					<div className={["content-section", classes.contentSection].join(" ")}>
						{this.state.isData ? (
							<>
								<div className={classes.content}>
									<div className="d-flex w-100 mb-3">
										<div className="d-flex ml-auto">
											<Link to="/organization-manager/new" className="btn-bim small bim-pd-btn">
												{t(transPage + 'new_orgmanager')}
											</Link>
										</div>
									</div>
								</div>
								<DataTable
									columns={columnsTable}
									data={this.state.dataManager}
									className={["table table-bordered dataTable", classes.table].join(" ")}
									role="grid"
									defaultSortField="manager"
									defaultSortAsc={false}
									selectableRowsHighlight={true}
									highlightOnHover={true}
									noDataComponent={t(transPage + "no_organization")}
								/>
								<div className={classes.pagination}>
									<Pagination
										activePage={this.state.activePage}
										itemsCountPerPage={this.state.limitPageData}
										totalItemsCount={this.state.dataCount}
										pageRangeDisplayed={4}
										onChange={this.handlePageChange.bind(this)}
										itemClass="page-item"
										linkClass="page-link"
										firstPageText={t(transPageT + "first")}
										prevPageText={t(transPageT + "prev")}
										nextPageText={t(transPageT + "next")}
										lastPageText={t(transPageT + "last")}
									/>
									<div className="d-flex ml-3">
										<span className="mt-auto mr-0 mb-auto ml-auto" style={{ font: "12px Roboto", color: "#707070" }}>
											{t(transPageT + "showing")} {(this.state.activePage - 1) * this.state.limitPageData + 1} {t(transPageT + "of")} {this.state.currentPageData} {t(transPageT + "from")} {this.state.dataCount}
										</span>
									</div>
								</div>
							</>
						) : (
							<div className="section-loader">
								<Loader loading={!this.state.isData} />
							</div>
						)}
					</div>
				</div>
				{this.state.dataManager && <ModalDeleteManager show={this.state.isDeleteManager} data={this.state.activeManager} onHide={() => this.modalDeleteManager(false)} action={this.deleteOrgManager} />}

			</>
		);
	}
}
export default withTranslation()(OrgManagers);

