import React, { Component } from 'react'
import { Modal } from "react-bootstrap"
import { withTranslation } from 'react-i18next';

class DeleteOrgManager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isChecked: false,

            transPage: "new_manager."
        }
    }

    onOpenModal = () => {

    }

    action = () => {
        this.props.action()
    }

    onChangeValue = () => {
        this.setState({
            isChecked: !this.state.isChecked
        })
    }

    render() {
        const { t } = this.props
        const {transPage} = this.state
        const manager = this.props.data

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="md"
                onShow={this.onOpenModal}
                className='modal-bim'
            >
                <Modal.Header closeButton className="modal-bim-header">
                    <Modal.Title> {t(transPage + "delete_orgmanager")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-bim-body'>
                    <div className='modal-bim-content'>
                        <p>
                            Are you sure you want to delete <strong>{`${manager.first_name} ${manager.last_name}`}</strong> ?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='modal-bim-footer'>
                    <div className='w-100 d-flex justify-content-between'>
                        <button className='btn-bim btn-bim-white small bim-pd-btn uppercase' onClick={this.props.onHide}>
                            {t("cancel")}
                        </button>
                        <button className='btn-bim small bim-pd-btn uppercase' onClick={this.action}>
                            {t("delete")}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default withTranslation()(DeleteOrgManager);

