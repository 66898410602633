import React, { Component } from 'react';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';

import classes from './Layout.module.css';
import { Helmet } from 'react-helmet-async';

export default class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
            <>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE} | {this.props.title}</title>
                </Helmet>
				<div className={classes.wrapper}>
					<Header title={this.props.title} isBack={this.props.isBack} />
					<div className={classes.wrapperContent}>
						<Sidebar />
						{this.props.children}
					</div>
				</div>
			</>
		);
	}
}
