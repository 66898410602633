import React, { Component } from 'react'
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Pagination from "react-js-pagination";
import { withTranslation } from 'react-i18next';
import { MdDelete } from "react-icons/md";

import notification from "../../lib/notification"

import ModalDeleteOwner from '../../components/Modal/DeleteOwner/DeleteOwner';
import Loader from "../../components/Loader/Loader";
import Context from "../../pages/Context";

import OwnerApi from "../../api/OwnerApi";

import classes from "./Owners.module.css"

class Owners extends Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            data: [
            ],

            dataOwners: [],
            isData: false,

            activePage: 1,
            limitPageData: 10,
            currentPageData: 0,
            totalCount: 0,
            dataCount: 0,

            activeOwner: false,
            isDeleteOwner: false,

            transPage: 'owners_list.',
            transPageT: "table."
        };
    }

    componentDidMount() {
        this.getListOwners()
    }

    getListOwners = () => {
        const { user } = this.context;
        const { activePage } = this.state
        const ownerApi = new OwnerApi();

        if (user) {
            let params = {
                page: activePage ? activePage : 1
            };

            ownerApi
                .getListOwners(params)
                .then((res) => {
                    this.setState(
                        {
                            dataOwners: res.data.results,
                            isData: true,
                            dataCount: res.data.count
                        },
                        () => {
                            this.setPagination();
                        }
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    setPagination = () => {
        const { dataOwners } = this.state;
        let totalData = dataOwners.length;
        let currentPageData;

        if (this.state.activePage === 1) {
            if (totalData >= this.state.limitPageData) {
                currentPageData = this.state.limitPageData;
            } else if (totalData < this.state.limitPageData) {
                currentPageData = totalData;
            }
        } else {
            currentPageData = (this.state.activePage - 1) * this.state.limitPageData + 1;
        }
        this.setState({
            currentPageData: currentPageData,
            totalCount: totalData,
        });
    };

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber,
        }, () => {
            this.getListOwners();
        });
    };

    modalDeleteOwner = (param) => {
        if (param) {
            this.setState({
                activeOwner: param,
                isDeleteOwner: !this.state.isDeleteOwner,
            });
        } else {
            this.setState({
                activeOwner: false,
                isDeleteOwner: false,
            });
        }
    };

    deleteOwner = () => {
        const { notify, notifAlertfy } = notification()

        if (this.state.dataOwners && this.state.activeOwner) {
            const ownerApi = new OwnerApi();
            ownerApi
                .deleteOwner(this.state.activeOwner.id)
                .then((res) => {
                    if (res) {
                        this.setState(
                            {
                                activeOwner: false,
                                isDeleteOwner: false,
                            },
                            () => {
                                notify('delete')
                                this.getListOwners();
                            }
                        );
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                    notifAlertfy(err.response)
                });
        }
    };

    render() {
        const { t } = this.props;
        const { transPage, transPageT } = this.state
        const { user } = this.context;

        let cl
        if (user) {
            cl = JSON.parse(user);
        }

        const columnsTable = [
            {
                name: t(transPage + 'name'),
                selector: (row) => row.full_name,
                sortable: true,
                cell: (row) => (
                    <Link to={`/owner/edit/${row["id"]}`}>
                        <span>{row["full_name"] || row["first_name"] || (row["email"] ? row["email"].substring(0, row["email"].indexOf("@")) : '')}</span>
                    </Link>
                ),
            },
            {
                name: t('email'),
                selector: (row) => row.email,
                sortable: true,
                cell: (row) => row["email"],
            },
            {
                name: t(transPage + 'Client'),
                selector: (row) => row.client,
                sortable: true,
                cell: (row) => {
                    // let html = row.client.map((o, i) => {
                    //     return <div key={`${i}-${o.id}`} style={{ width: '32px' }} className='me-2'>{o.name}</div>
                    // })
                    return <div className='w-100 h-100 d-flex flex-wrap'>{row.client ? row.client.name : ''}</div>
                },
            },
            {
                name: "",
                selector: (row) => row.id,
                minWidth: '80px',
                maxWidth: '80px',
                sortable: false,
                cell: (row) => (cl.is_superuser) && <MdDelete className={classes.icon} key={row["id"]} onClick={() => this.modalDeleteOwner(row)} />,
            },
        ]
        return (
            <>
                <div className="main-section">
                    <div className={["content-section", classes.contentSection].join(" ")}>
                        {this.state.isData ? (
                            <>
                                <div className={classes.content}>
                                    <div className="d-flex w-100 mb-3">
                                        <div className="d-flex ml-auto">
                                            <Link to="/owners/new" className="btn-bim small bim-pd-btn">
                                                {t(transPage + 'new_owner')}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    columns={columnsTable}
                                    data={this.state.dataOwners}
                                    className={["table table-bordered dataTable", classes.table].join(" ")}
                                    role="grid"
                                    defaultSortField="email"
                                    defaultSortAsc={false}
                                    selectableRowsHighlight={true}
                                    highlightOnHover={true}
                                    noDataComponent="no owner found"
                                />
                                <div className={classes.pagination}>
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.limitPageData}
                                        totalItemsCount={this.state.dataCount}
                                        pageRangeDisplayed={4}
                                        onChange={this.handlePageChange.bind(this)}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText={t(transPageT + "first")}
                                        prevPageText={t(transPageT + "prev")}
                                        nextPageText={t(transPageT + "next")}
                                        lastPageText={t(transPageT + "last")}
                                    />
                                    <div className="d-flex ml-3">
                                        <span className="mt-auto mr-0 mb-auto ml-auto" style={{ font: "12px Roboto", color: "#707070" }}>
                                            {t(transPageT + "showing")} {(this.state.activePage - 1) * this.state.limitPageData + 1} {t(transPageT + "of")} {this.state.currentPageData} {t(transPageT + "from")} {this.state.dataCount}
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="section-loader">
                                <Loader loading={!this.state.isData} />
                            </div>
                        )}
                    </div>
                </div>
                {this.state.dataOwners && <ModalDeleteOwner show={this.state.isDeleteOwner} data={this.state.activeOwner} onHide={() => this.modalDeleteOwner(false)} action={this.deleteOwner} />}

            </>
        )
    }
}
export default withTranslation()(Owners);

