import axios from "axios";
import config from "../lib/Config";

class ClientApi {
	base_api = config.base_api;
	token = config.token;

	getListClients(param) {
		return axios.get(this.base_api + `clients/`, {
			params: param,
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	getClientDetail(clientId) {
		return axios.get(this.base_api + `clients/${clientId}/`, {
			params: {},
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}
}

export default ClientApi;
