import React, { Component } from 'react'
import { Modal } from "react-bootstrap"

import classes from './Request.module.css'

export default class Request extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    onOpenModal = () => {

    }

    action = () => {
        
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                onShow={this.onOpenModal}
                className='modal-bim'
            >
                <Modal.Header closeButton className="modal-bim-header">
                    <Modal.Title> {this.props.title} </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-bim-body'>
                    <div className='modal-bim-content'>
                        <div>
                            <p className={classes.subTitle}>General Information</p>
                            <div className='d-flex'>
                                <div className={['col-md-5 p-0', classes.modalLabel].join(' ')}>
                                    <p>Company name:</p>
                                    <p>Company org number:</p>
                                    <p>Project name:</p>
                                </div>
                                <div className={['col-md-7 p-0', classes.modalContent].join(' ')}>
                                    <p>Company name</p>
                                    <p>Company org number</p>
                                    <p>Project name</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className={classes.subTitle}>Contact Information</p>
                            <div className='d-flex'>
                                <div className={['col-md-5 p-0', classes.modalLabelC].join(' ')}>
                                    <p>Contact person:</p>
                                    <p>Email:</p>
                                    <p>Phone number:</p>
                                </div>
                                <div className={['col-md-7 p-0', classes.modalContentC].join(' ')}>
                                    <input type='text' className='form-control modal-bim-input' />
                                    <input type='text' className='form-control modal-bim-input' />
                                    <input type='text' className='form-control modal-bim-input' />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='modal-bim-footer'>
                    <div className='w-100 d-flex justify-content-between'>
                        <button className='btn-bim btn-bim-white small bim-pd-btn' onClick={this.props.onHide}>
                            CANCEL
                        </button>
                        <button className='btn-bim small bim-pd-btn' onClick={this.action}>
                            SEND
                        </button>
                    </div>

                </Modal.Footer>
            </Modal>

        )
    }
}
