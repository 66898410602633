import axios from 'axios';
import config from '../lib/Config';

class AssetsApi {
	base_api = config.base_api;
	token = config.token;

	getAssets(params) {
		return axios.get(this.base_api + `icons/`, {
			params: params,
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}
	deleteAsset(assetId) {
		return axios.delete(this.base_api + `icons/${assetId}/`, {
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'application/json',
			},
		});
	}
	addAsset(data) {
		var bodyFormData = new FormData();

		if (data) {
			bodyFormData.append(`client`, data.client);
			bodyFormData.append(`name`, data.name);

			if (data.file) {
				bodyFormData.append(`file`, data.file);
			}
		}

		return axios.post(this.base_api + `icons/`, bodyFormData, {
			params: {},
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'multipart/form-data',
			},
		});
	}
	updateAsset(assetId, data) {
		var bodyFormData = new FormData();
		if (data) {
			bodyFormData.append(`name`, data.name);

			if (data.file) {
				bodyFormData.append(`file`, data.file);
			}
		}

		return axios.patch(this.base_api + `icons/${assetId}/`, bodyFormData, {
			headers: {
				Authorization: 'Token ' + this.token,
				'Content-Type': 'multipart/form-data',
			},
		});
	}
}

export default AssetsApi;
