import axios from "axios";
import config from "../lib/Config";
class ManagerApi {
	base_api = config.base_api;
	token = config.token;

	addManager(data) {
		return axios.post(this.base_api + `managers/`, data, {
			params: {},
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	getListManagers(params) {
		return axios.get(this.base_api + `managers/`, {
			params: params,
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	getManagerDetails(id) {
		return axios.get(this.base_api + `managers/${id}/`, {
			params: {},
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}

	updateManager(managerId, data){
        return axios.patch(this.base_api + `managers/${managerId}/`, data,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
	}

	deleteManager(managerId){
        return axios.delete(this.base_api + `managers/${managerId}/`,{
            headers:{
                "Authorization":"Token "+this.token,
                "Content-Type":"application/json"
            }
        })
	}
	
	getListUsersAsPM(params) {
		return axios.get(this.base_api + `users/get_names/`, {
			params: params,
			headers: {
				Authorization: "Token " + this.token,
				"Content-Type": "application/json",
			},
		});
	}
}

export default ManagerApi;
