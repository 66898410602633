import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import notification from "../../lib/notification";
import errorHandler from "../../lib/errorHandler";
import InputMotion from "../../components/Input/InputMotion";
import Logo from "../../components/Logo/Logo";
import { checkInputValue } from "../../lib/Common";
import RegisterApi from "../../api/RegisterApi";

import classes from "./Register.module.css";

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canRegister: false,
			isRegister: false,

			isPrivacy: false,

			username: true,
			usernameError: false,
			companyName: true,
			firstName: true,
			lastName: true,
			password: true,
			organizationNumber: true,
			phoneNumber: true,

			transPage: "sign_up.",
			transPageErr: "error.",
		};
	}

	checkingValue = () => {
		let username = document.getElementById("username").value;
		let company = document.getElementById("companyName").value;
		let firstname = document.getElementById("firstName").value;
		let lastName = document.getElementById("lastName").value;
		let password = document.getElementById("password").value;
		let phone = document.getElementById("phoneNumber").value;

		if (username && company && firstname && lastName && password.length >= 8 && phone && this.state.isPrivacy) {
			this.setState({
				canRegister: true,
			});
		} else {
			this.setState({
				canRegister: false,
			});
		}
	};

	inputOnChange = (e, validation) => {
		const htmlId = e.target.id;
		const htmlText = e.target.value
		const stateValue = checkInputValue(htmlText);

		let lth = ["firstName"].includes(htmlId) ? 1 : 8

		if (validation) {
			if (["username"].includes(htmlId)) {
				const isEmail = htmlText.includes("@")
				if (isEmail && htmlText.length > 3){
					this.setState({
						[htmlId]: stateValue,
					});
				} else {
					this.setState({
						[htmlId]: false,
					});
				}
			} else {
				if (e.target.value.length >= lth) {
					this.setState({
						[htmlId]: stateValue,
					});
				} else if (e.target.value.length < lth) {
					this.setState({
						[htmlId]: false,
					});
				}
			}

		} else {
			this.setState(
				{
					[htmlId]: stateValue,
				},
				() => {
					this.checkingValue();
				}
			);
		}
	};

	privacyOnChange = (e) => {
		this.setState(
			{
				isPrivacy: e.target.checked,
			},
			() => {
				this.checkingValue();
			}
		);
	};

	register = () => {
		const registerApi = new RegisterApi();
        const { notifAlertfy } = notification()

		const data = {
			client: {
				name: this.state.companyName,
				// organization_number: this.state.organizationNumber,
				// contact_person: this.state.contactPerson,
				phone: this.state.phoneNumber,
			},
			first_name: this.state.firstName,
			last_name: this.state.lastName,
			email: this.state.username,
			password: this.state.password,
		};

		registerApi
			.registerUser(data)
			.then((res) => {
				const dataReturn = res.data;
				if (dataReturn) {
					this.setState({
						isRegister: true,
					});
				}
			})
			.catch((err) => {
				console.log(err.response);
				if (err && err.response) {
					errorHandler(err.response.data, `${err.response.status} ${err.response.statusText} - Failed to register new user ${JSON.stringify(data)}`)
					if (err && err.response) {
						let errText
						if (err.response.data && err.response.data[0]) {
							errText = err.response.data[0]

							this.setState({
								usernameError: err.response.data[0],
								username: false,
								canRegister: false
							})
						} else if (err.response.data?.last_name) {
							errText = err.response.data?.last_name[0]

							this.setState({
								lastName: false,
								canRegister: false
							})
						} else if (err.response.data?.first_name) {
							errText = err.response.data?.first_name[0]

							this.setState({
								firstName: false,
								canRegister: false
							})
						} else {
							errText = `${err.response.status} - ${err.response.statusText}`
						}
						notifAlertfy(err.response, errText)
					}
				}
			});
	};

	render() {
		const { t } = this.props;
		const { transPage } = this.state;

		return (
			<>
				<Helmet>
					<title>{process.env.REACT_APP_TITLE} | Register</title>
				</Helmet>
				<Logo />
				<div className={[classes.register, "container"].join(" ")}>
					{!this.state.isRegister ? (
						<>
							<h1>{t(transPage + "new_user")}</h1>
							<div className={classes.contentRegister}>
								<div className={classes.content}>
									<InputMotion id="username" label={t(transPage + "email")} type="email" important={true} desc={!this.state.usernameError ? t(transPage + "used_to_log_in") : this.state.usernameError} onChange={(e) => this.inputOnChange(e, true)} status={this.state.username} />
									<hr />
									<InputMotion id="companyName" label={t(transPage + "company_name")} important={true} desc={false} onChange={(e) => this.inputOnChange(e)} status={this.state.companyName} />
									<InputMotion id="phoneNumber" label={t(transPage + "phone")} important={true} desc={t(transPage + "phone_caption")} onChange={(e) => this.inputOnChange(e)} status={this.state.phoneNumber} />
								</div>
								<div className={classes.content}>
									<InputMotion id="password" label={t(transPage + "password")} type="password" important={true} desc={t(transPage + "min_char")} onChange={(e) => this.inputOnChange(e, true)} status={this.state.password} />
									<hr />
									<div className={classes.contentName}>
										<InputMotion id="firstName" label={t(transPage + "firstname")} important={false} onChange={(e) => this.inputOnChange(e, true)} status={this.state.firstName} />
										<InputMotion id="lastName" label={t(transPage + "lastname")} important={false} onChange={(e) => this.inputOnChange(e)} status={this.state.lastName} />
									</div>
									{/* <InputMotion id="contactPerson" label={t(transPage + "contact_person")} important={true} desc="9 digits, without space" onChange={(e) => this.inputOnChange(e, true)} status={this.state.contactPerson} /> */}
								</div>
							</div>
							<div className={[classes.privacy, "mt-3"].join(" ")}>
								<input id="one" type="checkbox" className={classes.cKanim} onChange={(e) => this.privacyOnChange(e)} />
								<label htmlFor="one">
									<span></span>
									<a href="https://digibim.no/terms-privacy-policy/" target="_blank" rel="noreferrer">
										{t(transPage + "confirm_statement")}
									</a>
									<ins>
										<i>
											{t(transPage + "confirm_statement")}
										</i>
									</ins>
								</label>
							</div>
							<div className={classes.action}>
								<Link to="/login">
									<button className={["btn-bim", classes.btnCancel].join(" ")}>{t("cancel")}</button>
								</Link>
								<button className={["btn-bim", classes.btnReg, !this.state.canRegister && "bim-not-allowed"].join(" ")} onClick={this.state.canRegister ? this.register : undefined}>
									{t("register")}
								</button>
							</div>
						</>
					) : (
						<div className={classes.isRegister}>
							{t(transPage + "email_notification")}
							<Link to="/login" className="btn-bim">
								{t("ok")}
							</Link>
						</div>
					)}
				</div>
			</>
		);
	}
}

export default withTranslation()(Register);
